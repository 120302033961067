import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  public hideReadMore: boolean = false;
  constructor() { }

  ngOnInit(): void {
    window.analytics.page('/terms-conditions');
  }

}
