<div class="container my-5 pt-5 mx-auto">
  <section class="mx-3 section-personal-info-1">
    <a class="pointer" routerLink="/privacy-policy"
      ><img class="pointer" src="../../../assets/img/arrow-left.png"
    /></a>
    <h1 class="display-1 pt-4">Personal Information</h1>

    <div class="content"  [ngStyle]="{
      height: hideReadMore ? '100%' : '1000px',
      overflow: hideReadMore ? 'auto' : 'hidden'
    }">

    
    <div class="row  mt-5">
      <div class="col-md-12 mt-3">
        <h5 class="fw-700 text-primary">
          Here’s what Nevly does with your personal information
        </h5>
        <ngb-accordion
          [closeOthers]="true"
          #a="ngbAccordion"
          activeIds="custom-panel-0"
          class="border-0"
        >
          <ngb-panel class="border-0" id="{{ 'custom-panel-0' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Why?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              Financial companies choose how they share your personal
              information. Federal law gives consumers the right to limit some
              but not all sharing. Federal law also requires us to tell you how
              we collect, share, and protect your personal information. Please
              read this notice carefully to understand what we do.
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-1' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  What?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                The types of personal information we collect and share depend on
                the product(s) or service(s) you have with us. This information
                can include:
              </p>
              <ul>
                <li>Social Security number and employment information</li>
                <li>Credit scores and income</li>
                <li>Payment history and credit history</li>
                <li>Transaction history, assets, and account balances</li>
              </ul>
              <p>
                When you are no longer our customer, we continue to share your
                information as described in this notice.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-2' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  How?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              All financial companies need to share customers’ personal
              information to run their everyday business. In the section below,
              we list the reasons financial companies can share their customers’
              personal information; the reasons Nevly chooses to share; and
              whether you can limit this sharing.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>

    <div class="row  mt-5">
      <div class="col-md-12 mt-3">
        <h5 class="fw-700 text-primary">What Do We</h5>
        <ngb-accordion
          [closeOthers]="true"
          #a="ngbAccordion"
          activeIds="custom-panel-0"
          class="border-0"
        >
          <ngb-panel class="border-0" id="{{ 'custom-panel-0' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  How does Nevly protect my personal information?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              To protect your personal information from unauthorized access and
              use, we use security measures that comply with federal law. These
              measures include computer safeguards and secured files and
              buildings. For more information, visit
              https://www.nevly.com/security
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-1' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  How does Nevly collect my personal information?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>How does Nevly collect my personal information?</p>
              <ul>
                <li>
                  open an account or use your credit card, prepaid card, or
                  securities account;
                </li>
                <li>Apply for a loan;</li>
                <li>Give us your contact information; and/or</li>
                <li>Provide account information</li>
              </ul>
              <p>
                We also collect your personal information from others, such as
                credit bureaus, affiliates, or other companies.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-2' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Why can't I limit all sharing?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>Federal law gives you the right to limit only sharing for:</p>
              <ul>
                <li>
                  affiliates’ everyday business purposes—information about your
                  creditworthiness
                </li>
                <li>affiliates from using your information to market to you</li>
                <li>sharing for nonaffiliates to market to you</li>
              </ul>
              <p>
                State laws and individual companies may give you additional
                rights to limit sharing.
              </p>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <h5 class="fw-700 text-primary">Definitions</h5>
        <ngb-accordion
          [closeOthers]="true"
          #a="ngbAccordion"
          activeIds="custom-panel-0"
          class="border-0"
        >
          <ngb-panel class="border-0" id="{{ 'custom-panel-0' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Affiliates
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              Companies related by common ownership or control. They can be
              financial and nonfinancial companies.
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-1' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Nonaffiliates
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                Companies not related by common ownership or control. They can
                be financial and nonfinancial companies.
              </p>
              <ul>
                <li>
                  Nevly does not share with nonaffiliates so they can market to
                  you.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-2' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Joint marketing
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                A formal agreement between nonaffiliated financial companies
                that together market financial products or services to you.
              </p>
              <ul>
                <li>Nevly does not jointly market.</li>
              </ul>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mt-3">
        <h5 class="fw-700 text-primary">Other Important Information</h5>
        <ngb-accordion
          [closeOthers]="true"
          #a="ngbAccordion"
          activeIds="custom-panel-0"
          class="border-0"
        >
          <ngb-panel class="border-0" id="{{ 'custom-panel-0' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Important Notice about Credit Reporting
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              We may report information about your account(s) to credit bureaus
              and/ or consumer reporting agencies. Late payments, missed
              payments, or other defaults on your account(s) may be reflected in
              your credit report and/or consumer report.
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-1' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  California residents
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                Under California law, we will not share information we collect
                about you outside of Nevly’s affiliated companies unless
                permitted by law. We may share information with your consent, or
                to offer our products and services to you and to provide
                customer service. We will limit sharing amongst Nevly companies
                to the extent required by California law.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-2' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Nevada residents
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                Nevada law requires us to tell you that you may request to be
                placed on our internal “do not call” list at any time by
                emailing privacy@nevly.com. We are providing this notice to you
                pursuant to state law. You may obtain more information by
                contacting the Nevada Attorney General at
                bcpinfo@ag.state.nv.us, (702) 486-3132, or at 555 E. Washington
                Ave., Suite 3900, Las Vegas, NV 89101
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-3' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Vermont residents
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>
                Under Vermont law, we will not share information we collect
                about you outside of Nevly’s affiliated companies unless
                permitted by law. We may share information if you consent, to
                offer our products and services to you, or to provide customer
                service. We may share information about your account with our
                affiliated companies, but we will not share information about
                your credit history with our affiliated companies unless you
                provide consent.
              </p>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>

    <div class="row  mt-5" style="overflow: auto;">
      <div class="col-md-12">
        <table class="nevly-table">
          <thead>
            <th style="padding:10px;min-width: 600px" >Reasons we can share your personal information?</th>
            <th [width]="300" class="text-truncate text-center">Does Nevly Share?</th>
            <th [width]="300" class="text-truncate text-center">Can you limit this sharing?</th>
          </thead>
          <tbody>
            <tr>
              <td><p><strong>For our everyday business purposes</strong> — such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus.</p></td>
              <td class="text-center"><p>Yes</p></td>
              <td class="text-center"><p>No</p></td>
            </tr>
            <tr>
              <td><p><strong>For our marketing purposes</strong> — to offer our products and services to you.</p></td>
              <td class="text-center"><p>Yes</p></td>
              <td class="text-center"><p>No</p></td>
            </tr>
            <tr>
              <td><p><strong>For joint marketing with other financial companies
              </strong></p></td>
              <td class="text-center"><p>yes</p></td>
              <td class="text-center"><p>No</p></td>
            </tr>
            <tr>
              <td><p><strong> our affiliates' everyday business purposes</strong> — information about your transactions and experiences

              </p></td>
              <td class="text-center"> <p>Yes</p></td>
              <td class="text-center"><p>No</p></td>
            </tr>
            <tr>
              <td><p><strong>For our affiliates to market to you</strong></p></td>
              <td class="text-center"><p>No</p></td>
              <td class="text-center"><p>N/A</p></td>
            </tr>
            <tr>
              <td><p><strong>For nonaffiliates to market to you
              </strong></p></td>
              <td class="text-center"><p>No</p></td>
              <td class="text-center"><p>N/A</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row  mt-5">
      <div class="col-md-12 mt-3">
        <ngb-accordion
          [closeOthers]="true"
          #a="ngbAccordion"
          activeIds="custom-panel-0"
          class="border-0"
        >
          <ngb-panel class="border-0" id="{{ 'custom-panel-0' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Who is providing this notice?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              We may report information about your account(s) to credit bureaus
              and/ or consumer reporting agencies. Late payments, missed
              payments, or other defaults on your account(s) may be reflected in
              your credit report and/or consumer report.
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-1' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  To limit our sharing
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li>Email: You can contact us at privacy@nevly.com</li>
                <li>
                  In the app: Log in, click on your account profile, and go to
                  the Privacy section to adjust preferences
                </li>
                <li>
                  Please note: If you are a new customer, we can begin sharing
                  your information 30 days from the date we sent this notice.
                  When you are no longer our customer, we continue to share your
                  information as described in this notice. You can contact us
                  any time to limit our sharing.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel class="border-0" id="{{ 'custom-panel-2' }}">
            <ng-template ngbPanelHeader let-opened="opened" class="border-0">
              <div
                class="accordion-button custom-header bg-white p-0"
                [class.collapsed]="!opened"
              >
                <button
                  ngbPanelToggle
                  class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
                >
                  Questions?
                  <div
                    class="fs-24 fw-700 text-primary bg-white arrow"
                    *ngIf="opened"
                  >
                    -
                  </div>
                  <div
                    class="fs-20 fw-700 text-primary bg-white"
                    *ngIf="!opened"
                  >
                    +
                  </div>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li>Visit us online at <a href="https://nevly.com">Nevly.com</a></li>
                <li>Email your question to privacy@nevly.com</li>
              </ul>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="read-more" *ngIf="!hideReadMore" [@fadeInOut]>
      <button class="btn custom-btn btn-bg text-white d-none d-md-block d-lg-block d-xl-block" (click)="hideReadMore = true">
        Read more
      </button>
      <img class="img-fluid d-block d-md-none d-lg-none d-xl-none mx-auto cursor" (click)="hideReadMore = true" src="../../../assets/img/readmore.png" />
    </div>
  </div>
  </section>
</div>
