
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      let top = document.getElementById('main-content');
      
        if (top !== null) {
          top.scrollIntoView({
            block: "start",
            behavior: 'smooth'
          });
          window.scrollTo(0 , 0);
          top = null;
        }
    }, 100);
  }
}
