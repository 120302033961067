import { environment } from './../../../environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links : any ;
  public isMenuCollapsed = true;
  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
  }
  constructor(public router: Router , private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.links = environment;
  }

  getActivatedRoute(){
    return this.route.snapshot.fragment;
  }

  goToUrl(link : any) {
    this.links.register === link && window.analytics.track('Checkout CTA Click');
    window.location.href = link;
  }

  //Add PWA to Homescreen
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if (window.pageYOffset >= 100) {
      (document.getElementById("lp-pom-box-101") as HTMLElement).classList.add("header-colored");
      (document.getElementById("lp-code-92") as HTMLElement).classList.add("header-colored");
      (document.getElementById("lp-code-158") as HTMLElement).classList.add("header-colored");
    } else {
      (document.getElementById("lp-pom-box-101") as HTMLElement).classList.remove("header-colored");
      (document.getElementById("lp-code-92") as HTMLElement).classList.remove("header-colored");
      (document.getElementById("lp-code-158") as HTMLElement).classList.remove("header-colored");
    }
  }

}
