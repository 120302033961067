<div class="my-5 mx-auto pt-5 container">
    <section class="mx-3 cookie-policy-1">
        <a routerLink="/privacy-policy"><img src="../../../assets/img/arrow-left.png" /></a>
      <h1 class="display-1 pt-4">Nevly’s Cookie Policy</h1>
      <div class="my-5 pt-5">
      <strong><p>How we collect cookies (technical bit)</p></strong>
      <p> Some cookies are needed to run the website. For example, when you log in, cookies help to recognize you and keep you logged in as you browse the website. We call these strictly necessary cookies.</p>
     
      <strong><p>We also use the following types of cookies:</p></strong>
      
      <ul>
          <li><strong><u>Analytical and performance cookies:</u></strong> These allow us to recognize and count the number of visitors to our website, and see how visitors browse around our website, so we can improve it where necessary.</li>
            <li><strong><u>Targeting cookies:</u></strong> These allow us to see what pages and links you have visited so we can provide more relevant ads. We may share this information with other organizations for the same purpose.</li>
            <li><strong><u>Analytics cookies:</u></strong>  These identify and analyze website trends, but do not identify you individually.</li>
        </ul>
        <p>These cookies are all “session cookies,” which means that they will end when you close your website browser.</p>
      
        <p>Can I change my preferences?</p>

        <p>Except for strictly necessary cookies, we can only use cookies if you agree.</p>
        <p>By using this website, you agree to us using cookies.</p>
        <p>You can change your browser settings to refuse the use of all or some cookies. However, if you block all cookies (including strictly necessary cookies), you may not be able to use all or some parts of our website. You can opt out of Google Analytics by clicking here.</p>
      <p> Please note, other organizations, such as advertising networks, may also use cookies to track you across different websites. We have no control over these cookies.</p>	
    </div>  
    </section>
      </div>
