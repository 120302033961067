<div class="my-5 mx-auto pt-5 container"  style="margin-bottom: 200px !important;">
  <div class="row">
      <h1 class="display-1 px-4">FAQ's</h1>
  </div>
  <div class="row mx-2 mt-3">
    <div class="col-md-12 overflow-hidden">
      <ul ngbNav #nav="ngbNav" #faqTab [activeId]="0" class="accordion-tab-custom overflow-auto flex-nowrap list-unstyled d-flex justify-content-between custom-border position-sm-absolute vw-sm-100 start-0 overflow-auto">
        <li *ngFor="let data of faqData ; let i = index;" [ngbNavItem]="i+0"  class="text-nowrap me-3"  >
          <a ngbNavLink [ngClass]="nav.activeId === (i + 0) ? 'text-primary  border-bottom border-primary py-3' : 'grey'" >{{data.title}}</a>
          <ng-template ngbNavContent>
          
            <div class="col-md-12 mt-3">
              <ngb-accordion
                [closeOthers]="true"
                #a="ngbAccordion"
                activeIds="custom-panel-0"
                class="border-0"
              >
                <ngb-panel class="border-0" id="{{'custom-panel-'+ k}}" *ngFor="let item of data.list; let k = index">
                  <ng-template ngbPanelHeader let-opened="opened" class="border-0">
                    <div
                      class="accordion-button custom-header bg-white p-0"
                      [class.collapsed]="!opened"
                    >
                      <button
                        ngbPanelToggle
                        class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0  text-primary bg-white shadow-none border-0"
                      >
                       {{item.q}}
                        <div class="fs-24 fw-700 text-primary bg-white arrow" *ngIf="opened">
                          -
                        </div>
                        <div class="fs-20 fw-700 text-primary bg-white" *ngIf="!opened">
                          +
                        </div>
                      </button>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    {{item.a}}
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
