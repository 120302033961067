import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public activeSlideMenu: any = 0;
  constructor(private title: Title, private meta: Meta) {}
  @HostListener('window:scroll', ['$event'])
  getScrollValue(event: any) {
    const slider = document.querySelector('.slider')?.getBoundingClientRect();
    let height: any = slider?.height;
    let calc: any = height / 9;
    switch (true) {
      case window.pageYOffset > 1843 && window.pageYOffset < 1843 + calc:
        this.activeSlideMenu = 0;
        break;
      case window.pageYOffset > 1843 + calc &&
        window.pageYOffset < 1843 + calc * 2:
        this.activeSlideMenu = 1;
        break;
      case window.pageYOffset > 1843 + calc * 2 &&
        window.pageYOffset < 1843 + calc * 3:
        this.activeSlideMenu = 2;
        break;
      case window.pageYOffset > 1843 + calc * 3 &&
        window.pageYOffset < 1843 + calc * 4:
        this.activeSlideMenu = 3;
        break;
      case window.pageYOffset > 1843 + calc * 4 &&
        window.pageYOffset < 1843 + calc * 5:
        this.activeSlideMenu = 4;
        break;
      case window.pageYOffset > 1843 + calc * 5 &&
        window.pageYOffset < 1843 + calc * 6:
        this.activeSlideMenu = 5;
        break;
      case window.pageYOffset > 1843 + calc * 6 &&
        window.pageYOffset < 1843 + calc * 7:
        this.activeSlideMenu = 6;
        break;
      case window.pageYOffset > 1843 + calc * 7 &&
        window.pageYOffset < 1843 + calc * 8:
        this.activeSlideMenu = 7;
        break;
      case window.pageYOffset > 1843 + calc * 8 &&
        window.pageYOffset < 1843 + calc * 9:
        this.activeSlideMenu = 8;
        break;
      // case window.pageYOffset > height + (calc * 5):
      //   this.activeSlideMenu = 5;
      //   break;

      default:
        console.log('No Menu');
    }
  }
  public slidesArr = [
    {
      heading: 'Put our members’ interest first.',
      subtitle:'Member’s Interest',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
      img: '../../../assets/img/about-slide-1.webp',
      bgImg : '../../../assets/img/about-step-1.svg',
    },
    {
      heading: 'Aim high, be bold. ',
      subtitle:'Aim High',
      content:
        'We learn from others then we write our own rules to be a pioneer in our industry and a north star for what is possible when institutions are willing to test and ultimately reject assumptions that have stifled innovation for those most in need of it. ',
      img: '../../../assets/img/about-slide-2.webp',
      bgImg : '../../../assets/img/about-step-2.svg',
    },
    {
      heading: 'Stay relentlessly balanced.',
      subtitle: 'Relentlessly Balanced',
      content:
        'We recognize and appreciate the gravity of our mission and we take it gravely seriously. We are equally passionate about enjoying every step of our journey and doing it with people we respect and appreciate for  their individuality',
      img: '../../../assets/img/about-slide-3.webp',
      bgImg : '../../../assets/img/about-step-3.svg',
    },
    {
      heading: 'We are results focused. ',
      subtitle : 'Results Focused',
      content:
        'We are focused on building a great business and a great company in an open, accessible environment. ',
      img: '../../../assets/img/about-slide-4.webp',
      bgImg : '../../../assets/img/about-step-4.svg',
    },
    {
      heading: 'Think globally, act locally. ',
      subtitle : 'Think Global, Act Global',
      content:
        'Improving the communities within which we operate is imprinted into our DNA. ',
      img: '../../../assets/img/about-slide-5.webp',
      bgImg : '../../../assets/img/about-step-5.svg',
    },
    {
      heading: 'Collaboration is king. ',
      subtitle : 'Collaboration',
      content:
        'We solve problems together and help each other out along the way. We keep our commitments and communicate diligently when we can’t.',
      img: '../../../assets/img/about-slide-6.webp',
      bgImg : '../../../assets/img/about-step-6.svg',
    },
    {
      heading: 'Get to the truth and make principle-based decisions. ',
      subtitle : 'Truth and Principle based',
      content:
        'We ask, listen, learn and collect data. We engage in constructive debate to reach conclusions and move forward together. ',
      img: '../../../assets/img/about-slide-7.webp',
      bgImg : '../../../assets/img/about-step-7.svg',
    },
    {
      heading: 'Be Nevly missionaries. ',
      subtitle : 'Nevly missionaries',
      content:
        'Our products, services, brand and business model is designed to be durable over the long term to achieve our mission – that is why we strive to make every person who comes into contact with our company or any of its people a missionary. forward together. ',
      img: '../../../assets/img/about-slide-6.webp',
      bgImg : '../../../assets/img/about-step-8.svg',
    },
    {
      heading: 'Bigger than just Nevly. ',
      subtitle : 'Bigger than Nevly',
      content:
        'We recognize that what we are doing is so much bigger than us and we are ok with that...in fact we like it that way.  ',
      img: '../../../assets/img/about-slide-9.webp',
      bgImg : '../../../assets/img/about-step-9.svg',
    },
  ];
  public accData = [
    {
      title: 'Member’s Interest',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Aim High',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Relentlessly Balanced',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Results Focussed',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Think Global,Act Local',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Collaboration',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Truth and Principle Based',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Nevly Missionaries',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
    {
      title: 'Bigger than Nevly',
      content:
        'We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.',
    },
  ];
  ngOnInit(): void {
    this.title.setTitle('Nevly - About Us');
    window.analytics.page('/about');
    this.meta.updateTag({
      name: 'description',
      content: 'About Us - Nevly is website provide the credit card scores.',
    });
  }

  getPosition(element: any) {
    var clientRect = element.getBoundingClientRect();
    return {
      left: clientRect.left + document.body.scrollLeft,
      top: clientRect.top + document.body.scrollTop,
    };
  }
}
