<div class="my-5 mx-auto pt-5 container">
  <section class="mx-3 section-terms-condition-1">
    <h1 class="display-1">Terms and Conditions</h1>
    <div
      class="content"
      [ngStyle]="{
        height: hideReadMore ? '100%' : '1000px',
        overflowY: hideReadMore ? 'sc' : 'hidden'
      }"
    >
      <strong class="mb-4">LAST UPDATED: SEPTEMBER 25, 2023</strong>

      <p>
        Welcome, and thank you for your interest in the Nevly Money product and
        online services (collectively, the “Service”) made available by Nevly
        Holdings Corp. (“Nevly,” “we,” or “us”).
      </p>

      <p>
        <strong><u>ACCEPTANCE OF TERMS OF SERVICE</u></strong>
      </p>

      <p>
        BY CLICKING “SIGN AND CONTINUE,” YOU AGREE THAT YOU HAVE READ AND
        UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO
        BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS (THE “TERMS”), INCLUDING
        <a routerLink="/privacy-policy">NEVLY’S PRIVACY POLICY</a>
        AND ALL OTHER TERMS AND CONDITIONS THAT APPLY TO OUR SERVICES (TOGETHER,
        THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS,
        THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE
        SERVICE, AND NEVLY’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN
        AGREEMENT BY NEVLY AND BY YOU TO BE BOUND BY THESE TERMS.
      </p>

      <p>
        <strong><u>MODIFICATION OF THESE TERMS OF SERVICE</u></strong>
      </p>

      <p>
        NEVLY RESERVES THE RIGHT TO MODIFY THESE TERMS OF SERVICE AT ANY TIME
        AND WILL NOTIFY YOU OF ANY SUCH CHANGES BY POSTING THE REVISED TERMS OF
        SERVICE ON THE NEVLY MONEY SITE (THE “SITE”). YOU SHOULD CHECK THESE
        TERMS OF SERVICE ON THE SITE PERIODICALLY FOR CHANGES. ALL CHANGES SHALL
        BE EFFECTIVE UPON POSTING. WE WILL DATE THE TERMS OF SERVICE WITH THE
        LAST DAY OF ANY REVISION. YOUR CONTINUED USE OF THE SERVICE AFTER ANY
        CHANGE TO THESE TERMS OF SERVICE SIGNIFIES YOUR AGREEMENT TO BE BOUND BY
        ANY SUCH CHANGES. NEVLY MAY TERMINATE, SUSPEND, CHANGE OR RESTRICT
        ACCESS TO ALL OR ANY PART OF THE SITE OR THE SERVICES WITHOUT NOTICE OR
        LIABILITY.
      </p>

      <p>
        <strong><u>ELIGIBILITY</u></strong>
      </p>

      <p>
        In order to use the Service, you (i) must be at least 18 years of age or
        the age of majority in your jurisdiction of residence; (ii) must be a US
        citizen or a legal US resident, (iii) must not be prohibited by law from
        using the Service including the applicable law of the State of Georgia,
        which prohibits the provision of credit repair services within the State
        of Georgia and (iv) have not been previously suspended or removed from
        the Service. By accepting these Terms, you represent and warrant to us
        that you are eligible to use the Service in accordance with the
        eligibility requirements set forth above.
      </p>

      <p>
        <strong
          ><u
            >NEVLY MONEY ACCOUNT REGISTRATION AND USER INFORMATION UPDATES</u
          ></strong
        >
      </p>

      <p>
        To access and use the Services, you must create an account (a “Nevly
        Money Account”) and complete the registration process. This process will
        require you to create a username and password. When you sign up for the
        Service, you agree to provide truthful, accurate, current and complete
        information, such as your name, email address, phone number, current and
        past addresses (including landlord details if you will enable rent
        reporting), birth date and Social Security Number (“Account
        Information”). You further represent that you are a legal owner of, and
        that you are authorized to provide us with, all Account Information and
        other information necessary to facilitate your use of the Service.
      </p>
      <p>
        You agree to update Nevly if the Account Information provided by you
        changes, and Nevly will not be held liable for any errors or fees that
        occur as a result of outdated Account Information.
      </p>
      <p>
        Should any of your Account Information change, you agree that you will
        update this information as soon as possible. To update your Account
        Information, you may go to the Settings tab on the Site dashboard and
        update your Account Information accordingly.
      </p>
      <p>
        Should you believe or have reason to believe that any of your Account
        Information has been compromised or that another person is accessing
        your Nevly Money account through some other means, you agree to notify
        us as soon as possible at support@nevly.com.
      </p>

      <p>
        <strong><u>ARBITRATION NOTICE</u></strong>
      </p>

      <p>
        Except for certain kinds of disputes described in Section 14, you agree
        that disputes arising under these Terms will be resolved by binding,
        individual arbitration, and BY AGREEING TO THESE TERMS, YOU AND NEVLY
        ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY
        CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR
        RIGHT TO GO TO COURT to assert or defend your rights under this contract
        (except for matters that may be taken to small claims court). Your
        rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or
        jury. (See Section 14).
      </p>

      <p>
        <strong><u>PRIVACY POLICY</u></strong>
      </p>

      <p>
        Your privacy is important to Nevly. The company highly values and
        respects the privacy of the personal information that we obtain from you
        when you use the Site or Services. For details on how we handle and
        protect data, Nevly maintains a
        <a routerLink="/privacy-policy">Privacy Policy</a>. We reserve the right
        to update the Privacy Policy at our discretion and that any changes made
        to our Privacy Policy are effective upon posting.
      </p>

      <p>
        <strong><u>THE SERVICES</u></strong>
      </p>

      <p>Section 1 - Overview of the Service.</p>
      <p>
        The Service provides you and other users access to services that are
        designed to help you improve your credit score and an account dashboard
        that enables you to observe changes in your credit score and receive
        other financial health tips where such services are owned and operated
        by Nevly, or by independent third-party service providers. Among other
        services, the Services include access to independent third-party:
        provision of a tri-bureau credit report together with associated credit
        monitoring, credit repair services, rental payment reporting services
        including the reporting of up to two (2) years of past rental payments,
        a credit builder card and associated account, credit score simulation
        services that enable you to be provided with tailored information on how
        to achieve a targeted increase in a credit score, emergency cash
        advances and other financial health improvement services. You understand
        and agree that your access and use of all third-party services is
        subject to the terms and conditions applicable to such services. NEVLY
        IS NOT RESPONSIBLE IN ANY WAY FOR YOUR USE OF THIRD-PARTY SERVICES AND
        ANY RESULTING DAMAGES. YOU CHOOSE TO USE THE SERVICE AND ANY THIRD-PARTY
        SERVICE AT YOUR OWN RISK.
      </p>
      <p>Rent Reporting Services.</p>
      <p>
        <i>Description of Rent Reporting Services.</i>
      </p>
      <p>
        If you are a renter, by using the Service and by submitting your Account
        Information, you agree that we (or a designated partner) may contact
        your landlord or property manager to verify any related rental
        information.
      </p>
      <p>
        <i>Credit Reporting Services Related to Rent Reporting.</i>
      </p>
      <p>
        We (or a designed partner) will report rent payments made by you to a
        credit reporting agency, which in part is designed to assist you in
        establishing a credit history and/or a credit score. In order to use the
        rent reporting aspect of the Service, you will need to provide us with
        certain information including personal information of your landlord or
        property manager (name, phone number and address) and any other
        information necessary for us to verify and validate the information with
        your landlord or property manager.
      </p>
      <p>
        <i>Verification of a Lease Agreement and Associated Terms,</i>
      </p>
      <p>
        To be eligible for reporting to a credit reporting agency, we (or a
        designated partner) will verify the existence of a valid written lease,
        including verifying that you are listed as a lessee. Additionally, we
        (or a designed partner) will need to verify certain lease details with
        your landlord or property manager (as application) (e.g., the amount and
        due dates of the payments under the lease agreement).
      </p>
      <p>
        <i>No Modification of Rental Information.</i>
      </p>
      <p>
        Once payments have been reported us (or a designated partner) to a
        credit reporting agency, the reporting of those payments cannot be
        modified, amended or canceled accept in accordance with the governing
        lease agreement.
      </p>
      <p>
        <i>Disputes Related to Rent Reporting.</i>
      </p>
      <p>
        Notwithstanding the above, any party may dispute any entry on a credit
        report provided by us (or a designated partner) to a credit reporting
        agency pursuant to the Fair Credit Reporting Act,15 U.S.C. § 1681.
      </p>
      <p>
        <i>No Liability for a Credit Reporting Agency.</i>
      </p>
      <p>
        Nevly (together with any designated partner contracted to provide this
        service) are independent companies not related with any credit reporting
        agency. Therefore, we are not responsible for how any credit reporting
        agency may manage or use the information provided to them. You hereby
        agree to hold Nevly (together with any designated partner) harmless
        against any such claims (all subject to the Credit Reporting Dispute
        Resolution Policy and the Fair Credit Reporting Act). Once the
        information related to your rental payments is transmitted to a credit
        reporting agency, that credit reporting agency will obtain an ownership
        interest in that data.
      </p>
      <p>
        <i>Dispute of Credit Reporting Information Policy and Procedures.</i>
      </p>
      <ol>
        <li>
          <p>
            Notice of Dispute. You may dispute an entry on your credit report
            derived from information provided by us (or a designated partner) to
            a credit reporting agency by notifying us either by mail or by other
            means available to you. You must provide the following information
            as part of any dispute of information provided by us (or a
            designated partner): (a) your name; (b) your contact information,
            including without limitation the address of the property for which
            we are reporting rent payments; (c) your Landlord’s name and (d) a
            brief description of the disputed information. You hereby represent
            and warrant that all information submitted to us pursuant to this
            sub-section is accurate.
          </p>
        </li>
        <li>
          <p>
            Investigation of Disputed Information. Upon our receipt of a notice
            of dispute we (or a designated partner) will conduct an
            investigation and make a determination of the status of the disputed
            information within ten (10) business days of receipt of the notice
            of dispute. The investigation shall include, but is not limited to,
            all relevant information provided by you. We will determine if (a)
            the dispute is frivolous or irrelevant and whether the information
            originally provided is accurate; (b) the disputed information is
            inaccurate; or (c) more information is necessary to make a final
            determination regarding the disputed information.
          </p>
        </li>

        <li>
          <p>
            Investigation of Disputed Information. Upon our receipt of a notice
            of dispute we (or a designated partner) will conduct an
            investigation and make a determination of the status of the disputed
            information within ten (10) business days of receipt of the notice
            of dispute. The investigation shall include, but is not limited to,
            all relevant information provided by you. We will determine if (a)
            the dispute is frivolous or irrelevant and whether the information
            originally provided is accurate; (b) the disputed information is
            inaccurate; or (c) more information is necessary to make a final
            determination regarding the disputed information.
          </p>
        </li>
        <li>
          <p>
            Notification of Determination. Once a final determination has been
            made regarding the disputed information, we (or a designated
            partner) will notify you for that purpose, by other means available
            to us (or a designated partner) within five (5) business days of the
            determination regarding the disputed information. If we (or a
            designated partner) determine that the dispute is frivolous or
            irrelevant, we shall notify you. This notice shall include: (a) the
            reasons for the determination and (b) identification of any
            information required to investigate the disputed information. If we
            (or a designated partner) determine that the disputed information is
            inaccurate, we shall notify you within five (5) business days of the
            date of determination and will promptly notify all credit reporting
            agencies to which we (or a designated partner) provided any such
            information to and request that they delete or modify the
            information as appropriate. If we (or a designated partner)
            determine that more information is necessary to complete our
            investigation, we will notify you and your landlord, if necessary,
            and request additional information from them in order to help make a
            final determination as to the disputed information.
          </p>
        </li>

        <li>
          <p>
            Additional Information Request. Upon receipt of the additional
            information, we (or a designated partner) shall make a final
            determination on the disputed information, considering all
            information provided by all parties, within thirty (30) days of
            receipt of the notice of dispute. Notice of said determination shall
            be forwarded to you as described above. You hereby agree to provide
            additional information if requested of you.
          </p>
        </li>
      </ol>

      <p>Credit Repair Services.</p>
      <p>
        As part of the Service, you are provided credit repair services. With
        respect to these credit repair services, upon clicking the “Sign and
        Continue” button you hereby acknowledge and agree that Nevly will hereby
        provide you with the following:
      </p>
      <ul>
        <li>
          <p>
            To evaluate your current credit reports as listed with applicable
            credit reporting agencies and to identify inaccurate, erroneous,
            false, or obsolete information. To advise you as to the necessary
            steps to be taken on your part in conjunction with to the disputing
            of any inaccurate, erroneous, false or obsolete information
            contained on your credit reports.
          </p>
        </li>
        <li>
          <p>
            To evaluate your current credit reports as listed with applicable
            credit reporting agencies and to identify inaccurate, erroneous,
            false, or obsolete information. To advise you as to the necessary
            steps to be taken on your part in conjunction with to the disputing
            of any inaccurate, erroneous, false or obsolete information
            contained on your credit reports.
          </p>
        </li>
        <li>
          <p>
            To review your credit profile status from the credit reporting
            agencies such as: Experian, Equifax and Transunion.
          </p>
        </li>
      </ul>
      <p>
        In connection with the provision of the credit repair services you
        hereby agree to the following:
      </p>
      <ul>
        <li>
          <p>
            You authorize Nevly to make, receive, sign, endorse, execute,
            acknowledge, deliver, and possess such applications, correspondence,
            contracts, or agreements, as necessary to improve your credit. Such
            instruments in writing of whatever and nature shall only be
            effective for any or all of the three credit reporting agencies,
            which are TransUnion, Experian, Equifax, and any other reporting
            agencies or creditor’s listed, as may be necessary or proper in the
            exercise of the rights and powers herein granted.
          </p>
        </li>
        <li>
          <p>
            This authorization may be revoked by you at any time by giving
            written notice to us by emailing us at support@nevly.com. Any
            activity made prior to revocation in reliance upon this
            authorization shall not constitute a breach of your rights. If not
            earlier revoked, this authorization will automatically expire six
            (6) months from the date of signature.
          </p>
        </li>
        <li>
          <p>
            Nevly is not authorized to make any further release or disclosure of
            the information received. This authorization does not authorize the
            release or disclosure of any information except as provided herein.
          </p>
        </li>
        <li>
          <p>
            You grant to Nevly authority to do, take, and perform, all acts and
            things whatsoever requisite, proper, or necessary to be done, in the
            exercise of repairing your credit with the three credit reporting
            agencies, which are TransUnion, Experian, Equifax and any other
            reporting agencies or creditor’s listed, as fully for all intents
            and purposes as you might or could do if personally present.
          </p>
        </li>
        <li>
          <p>
            You hereby release Nevly from any and all matters of actions, causes
            of action, suits, proceedings, debts, dues, contracts, judgments,
            damages, claims, and demands whatsoever in law or equity, for or by
            reason of any matter, cause, or thing whatsoever as based on the
            circumstances of this contract.
          </p>
        </li>
      </ul>

      <p>
        <i>Consumer Credit File Rights Under State and Federal Law</i>
      </p>
      <p>
        You have a right to dispute inaccurate information in your credit report
        by contacting the credit bureau directly. However, neither you nor a
        credit repair company or credit repair organization has the right to
        have accurate, current and verifiable information removed from your
        credit report. The credit bureau must remove accurate, negative
        information from your report only if it is over seven (7) years old.
        Bankruptcy information can be reported up to ten (10) years.
      </p>
      <p>
        You have a right to obtain a copy of your credit report from a credit
        bureau. You may be charged a reasonable fee. There is no fee, however,
        if you have been turned down for credit, employment, insurance, or a
        rental dwelling because of information in your credit report within the
        preceding 60 days. The credit bureau must provide someone to help you
        interpret the information in your credit file. You are entitled to
        receive a free copy of your credit report if you are unemployed and
        intend to apply for employment in the next 60 days, if you are a
        recipient of public welfare assistance, or if you have reason to believe
        that there is inaccurate information in your credit report due to fraud.
      </p>
      <p>
        You have a right to sue a credit repair organization that violated the
        Credit Repair Organization Act. This law prohibits deceptive practices
        by credit repair organizations.
      </p>
      <p>
        You have the right to cancel your contract with any credit repair
        organization for any reason within three (3) business days from the date
        you signed it.
      </p>
      <p>
        Credit bureaus are required to follow reasonable procedures to ensure
        that the information they report is accurate. However, mistakes may
        occur.
      </p>
      <p>
        You may, on your own, notify a credit bureau in writing that you dispute
        that accuracy of information in your credit file. The credit bureau must
        then reinvestigate and modify or remove inaccurate or incomplete
        information. The credit bureau may not charge any fee for this service.
        Any pertinent information and copies of all documents you have
        concerning an error should be given to the credit bureau.
      </p>
      <p>
        If the credit bureau's reinvestigation does not resolve the dispute to
        your satisfaction, you may send a brief statement to the credit bureau
        to be kept in your file, explaining why you think the record is
        inaccurate. The credit bureau must include a summary of your statement
        about disputed information with any report it issues about you.
      </p>
      <p>
        The Federal Trade Commission regulates credit bureaus and credit repair
        organizations. For more information contact: The Public Reference Branch
        Federal Trade Commission Washington, D.C. 20580.
      </p>
      <p>
        You hereby acknowledge that by clicking the “Sign and Continue” button,
        you confirm the fact that you agree and understand the terms contained
        herein and acknowledge that you have received a copy of your Consumer
        Credit File Rights.
      </p>

      <p>
        <strong><u>ACCOUNT REGISTRATION</u></strong>
      </p>

      <p>Section 2 - Registration and Your Account.</p>

      <p>
        You must have a registered account on Nevly to access and use the
        features of the Service. During registration of your account, you may be
        required (i) to provide us with some information about yourself, such as
        your name, phone number, email address, and other contact information,
        facial identifiers and other biometric data for account verification,
        your region, government ID card information, Social Security or
        Individual Taxpayer Identification Number and Service preferences, and
        (ii) to create a password. You agree that: (a) the information you
        provide to us is accurate and that you will keep it accurate and up to
        date at all times; (b) you are solely responsible for maintaining the
        confidentiality of your account and password on the Service; and (c) you
        accept responsibility for all activities that occur under your account.
        If you believe that your account or password is no longer secure, then
        you must immediately notify us by emailing us at: support@nevly.com.
      </p>

      <p>Consent to Obtain Credit Information.</p>

      <p>
        Nevly Partner’s with a third party to verify your identity
        (“StitchCredit”). You understand that by clicking “SIGN AND CONTINUE,”
        you are explicitly agreeing to and providing “written instructions”
        explicitly to StiitchCredit under the Fair Credit Reporting Act to
        obtain your credit information from one or more of the three (3)
        nationwide credit reporting agencies. Third-party sources, including
        your mobile carrier may be used to verify your identity. You authorize
        StitchCredit to obtain such information for you to confirm your
        identity. You authorize StitchCredit to obtain such information for you
        to confirm your identity, and, for as long as you are a member of
        StitchCredit, to provide you with your credit information. You may elect
        to terminate your account and this authorization at any time.
      </p>

      <p>
        By clicking “SIGN AND CONTINUE” and creating an account you accept
        StitchCredit’s
        <a
          href="https://app.termly.io/embed/terms-of-use/cd8446e8-9910-4a02-8f7c-36d9921a3bc7"
          target="_blank"
          >Terms of Use</a
        >
        and
        <a
          href="https://app.termly.io/embed/terms-of-use/958f774c-a642-41e7-9696-7ac060bdf44c"
          target="_blank"
          >Privacy Policy</a
        >. StitchCredit does not maintain critical personal data, much less sell
        or otherwise disclose your personal information to anyone else. You may
        opt-out of email correspondence, except confirmation Emails, which often
        contain important information about your account.
      </p>

      <p>
        <i>Mobile Identification (Option 1)</i>
      </p>
      <p>
        We will attempt to verify your identity by sending a text with a
        one-time code to your mobile phone number ending in xxxx. Message and
        data rates may apply.
      </p>

      <p>
        <i>Identity Quiz (Option 2)</i>
      </p>
      <p>
        Users who don’t qualify or unable to pass mobile verification must pass
        an identity quiz. Users will have at most two (2) chances to pass a
        quiz, assuming enough questions can be generated. Users may re-enter and
        try to pass the identity quiz again, however two (2) attempts (two (2)
        chances each) is the limit and will result in a 72-hour lockout.
      </p>

      <p>Section 3 - License to the Service.</p>
      <p>
        Subject to your complete and ongoing compliance with these Terms of
        Service, Nevly grants you, solely for your personal, non-commercial use,
        a limited, non-exclusive, non-transferable, non-sublicensable, revocable
        license to: (a) install and use one object code copy of any mobile or
        other downloadable application associated with the Service obtained from
        a legitimate marketplace on a mobile device that you own or control; and
        (b) access and use the Service.
      </p>

      <p>License Restrictions.</p>
      <p>
        Your right to use the Service is personal to you and you may not share,
        lease, lend, or otherwise make available the Service (or your access
        credentials) to any third-party unless otherwise required or permissible
        by Nevly.
      </p>
      <p>Ownership.</p>
      <p>
        Except for the limited rights granted above, Nevly retains all right,
        title, and interest, including all intellectual property rights, in and
        to the Service. ALL RIGHTS NOT EXPRESSLY GRANTED UNDER THESE TERMS ARE
        RESERVED BY NEVLY.
      </p>

      <p>
        Section 4 - User Generated Content and Testing and Evaluating the
        Service.
      </p>

      <p>User Content.</p>
      <p>
        In the event that you provide any comments, information, photographs,
        reviews, techniques or any other material contained in any communication
        that you may post, upload or submit through the Service or to Nevly
        ("User Content"), you shall, and hereby, grant Nevly a perpetual,
        royalty-free, irrevocable license to use, reproduce, modify, adapt,
        create derivative works from, publish, translate, license, transmit,
        distribute and otherwise exploit any or all portions of such User
        Content. In addition, you hereby irrevocably waive all “moral rights,”
        or similar protected rights under applicable law, in any such User
        Content. You also confirm to Nevly that the User Content is wholly
        original to you; that the User Content does not contain any confidential
        or proprietary information; that the User Content does not infringe any
        third-party's rights including intellectual property rights, and that
        Nevly is free to implement the User Content, at its discretion, as
        provided by you or modified by us, without obtaining further permission
        from you or any third-party, and without any additional consideration of
        any kind. Nevly is not obligated to review, pre-screen, monitor, delete
        or edit User Content. However, it reserves the right to do so at any
        time in its sole discretion, and to refuse, delete, remove or edit any
        User Content, in whole or in part, with or without notice, at its sole
        discretion and without any responsibility or liability. Notwithstanding
        the foregoing, Nevly will not be liable for any User Content.
      </p>

      <p>Feedback.</p>
      <p>
        Nevly may periodically request that you provide, and you agree to
        provide to Nevly, feedback regarding the use, operation, and
        functionality of the Service (“Feedback”), including any information
        about known or suspected bugs, errors or compatibility problems,
        suggested modifications, and user-desired features. You grant Nevly a
        perpetual, irrevocable, non-exclusive, worldwide, royalty-free, fully
        paid-up, fully sublicensable, and transferable right to use and
        incorporate Feedback into any products and services, to make, use, sell,
        offer for sale, import, and otherwise exploit such products and
        services, and to otherwise use, copy, distribute, and exploit the
        Feedback without restriction.
      </p>

      <p>
        <strong><u>CONFIDENTIALITY</u></strong>
      </p>

      <p>Section 5 - Confidentiality.</p>

      <p>
        You will maintain in strict confidence the confidentiality of, and not
        disclose to any third-party: (a) all non-public information disclosed by
        Nevly to you in connection with these Terms; and (b) all Feedback,
        Service performance data. Furthermore, you will not, and will not permit
        any third-party to, modify, disassemble, decompile, reverse engineer,
        rent, lease, loan, transfer, or copy any portion of, or circumvent or
        disable any security or other technological features of the Service.
      </p>

      <p>
        <strong><u>TERMINATION OF SERVICE</u></strong>
      </p>

      <p>Section 6 - Term and Termination.</p>

      <p>
        Subject to the terms of your Subscription Service (defined below), these
        Terms will be effective upon the earlier of your acceptance of these
        Terms or use of the Service and will continue until terminated (the
        “Term”); and we or you may terminate these Terms at any time with or
        without cause upon 14 days’ prior written notice to the other party.
        Nevly may terminate these Terms immediately upon notice to you if you
        are in material breach of any of the provisions of these Terms. Sections
        4, 5, 7, 13, 14, 17 – 22 will survive the termination of these Terms.
        Any continued access to and use of the Service after the Term and
        termination of these Terms will be pursuant to new terms and conditions.
      </p>

      <p>
        You may cancel the Service, without any penalty or obligation, at any
        time by emailing us at support@nevly.com.
      </p>

      <p>
        <strong><u>PAYMENT TERMS</u></strong>
      </p>

      <p>Section 7 - General Payment Terms.</p>

      <p>Subscription Fee.</p>
      <!-- TODO FIND CORRECT LINK -->
      <p>
        Our Service has a monthly cost of $29.99 beginning with the first month
        and then $59.99 per month beginning with the second month following your
        initial subscription with automatically recurring payments for periodic
        charges (“Subscription Service Monthly Price”) unless you cancel the
        Service, or we terminate it. If you activate the Service, you authorize
        Nevly or our third-party payment processors to periodically charge, on a
        going-forward basis and until cancellation of the Service, all accrued
        sums and taxes on or before the payment due date. For information on the
        Subscription Service Monthly Price, please see Services Chart. You may
        cancel the Service by sending an email to support@nevly.com from the
        email address used to create the user account with the words “CANCEL
        SUBSCRIPTION” in the subject line. You may also cancel the Service from
        your personal dashboard. Your cancellation will be effective at the end
        of the Service period within which you make the cancellation. YOUR
        CANCELLATION MUST BE RECEIVED BEFORE THE RENEWAL DATE IN ORDER TO AVOID
        CHARGE FOR THE NEXT SERVICE PERIOD.
      </p>

      <p>Fees Related to Credit Repair Services.</p>
      <p>
        With respect to the provision of the credit repair services portion of
        our Service, we assess no fees for the provision of these services until
        the six (6) month anniversary of the commencement of your signing up for
        the Service (the “Credit Repair Service Bill Date”). On the Credit
        Repair Service Bill Date, you will be assessed your normal Subscription
        Service Monthly Price as well as a one-time fee in the amount of $99.99
        (the “Credit Repair Services Fee”), which represents the total fee you
        will ever pay for the provision of the credit repair services.
      </p>

      <p>Subscription Service End Date.</p>
      <p>
        Our Service is designed to enable you to increase your credit score as
        quickly as possible but there are limits to the results that our Service
        can have on an individual consumer at a particular point in time. It is
        because of this that we have built into our Service a personalized
        subscription Service end date (“Service End Date”), which is
        personalized to you based on your target credit score and the makeup of
        your credit profile. You will be able to see your estimated Service End
        Date on your dashboard. Nevly reserves the right to change a Service End
        Date and will make reasonable efforts to notify you of the reason for
        the change.
      </p>

      <p>Subscription Service Progressive Pricing.</p>
      <p>
        In certain circumstances, we will apply a progressive pricing approach
        to our Service (“Progressive Pricing”) and where we apply Progressive
        Pricing, beginning on the fourth (4th) month anniversary of your signing
        up for the Service, we will reduce your Subscription Service Monthly
        Price amount by an amount that is personalized to your credit profile.
        When a user is eligible for Progressive Pricing, we will provide them
        with a notification on their Service dashboard together with the amount
        of their monthly Service reduction amount. Note that Nevly is under no
        obligation to make Progressive Pricing available to you and reserves the
        right to cancel or change its Progressive Pricing policy at any time.
      </p>

      <p>Price.</p>
      <!-- TODO FIND CORRECT LINK -->
      <p>
        Nevly reserves the right to determine pricing for the Service. Nevly
        will make reasonable efforts to keep pricing information published on
        the Service up to date. We encourage you to check our pricing page at
        <a routerLink="/" fragment="diff-chart">Services Chart</a> periodically
        for current pricing information. Nevly may change the fees for the
        Service, including additional fees or charges, if Nevly gives you
        advance notice of changes before they apply. Nevly, at its sole
        discretion, may make promotional offers with different features and
        different pricing to any of Nevly’s customers. These promotional offers,
        unless made to you, will not apply to your offer or these Terms.
      </p>

      <p>
        <strong><u>AUTHORIZATION</u></strong>
      </p>

      <p>Section 8 - Authorization.</p>

      <p>
        You authorize Nevly to charge all sums for the orders that you make and
        any level of Service you select as described in these Terms or published
        by Nevly, including all applicable taxes, to the payment method
        specified in your account. If you pay any fees with a credit card, then
        Nevly may seek pre-authorization of your credit card account prior to
        your purchase to verify that the credit card is valid and has the
        necessary funds or credit available to cover your purchase.
      </p>

      <p>
        <strong><u>DELINQUENT</u></strong>
      </p>

      <p>Section 9 - Delinquent Accounts.</p>

      <p>
        Nevly may suspend or terminate access to the Service, for any account
        for which any amount is due but unpaid. In addition to the amount due
        for the Service, a delinquent account will be charged with fees or
        charges that are incidental to any chargeback or collection of any the
        unpaid amount, including collection fees. If your payment method is no
        longer valid at the time a renewal Subscription Service fee is due, then
        Nevly reserves the right to delete your account and any information
        associated with your account without any liability to you.
      </p>

      <p>
        <strong><u>REPRESENTATIONS AND WARRANTIES</u></strong>
      </p>

      <p>
        Section 10 - Your Representations, Warranties, and Acknowledgements.
      </p>

      <p>
        You represent and warrant to Nevly and otherwise acknowledge and agree
        that:
      </p>
      <ul>
        <li>
          <p>
            Nevly is not and will not be liable to you or responsible for your
            use of independent third-party service providers;
          </p>
        </li>
        <li>
          <p>
            you will cooperate with us in connection with any investigation or
            inquiry relating to your use of the Service; and
          </p>
        </li>
        <li>
          <p>
            you will provide Nevly with information, authorizations and
            documentation as Nevly may request from time to time to carry out
            its obligations under these Terms of Service or to avoid violations
            of any applicable laws and will notify Nevly promptly in writing of
            any change in the information so furnished.
          </p>
        </li>
      </ul>

      <p>You will not:</p>
      <ul>
        <li>
          <p>
            use the Service for any illegal purpose or in violation of any
            local, state, national, or international law;
          </p>
        </li>
        <li>
          <p>
            harass, threaten, demean, embarrass, bully, or otherwise harm any
            other user of the Service;
          </p>
        </li>
        <li>
          <p>
            violate, encourage others to violate, or provide instructions on how
            to violate, any right of a third-party, including by infringing or
            misappropriating any third-party intellectual property right;
          </p>
        </li>
        <li>
          <p>
            access, search, or otherwise use any portion of the Service through
            the use of any engine, software, tool, agent, device, or mechanism
            (including spiders, robots, crawlers, and data mining tools) other
            than the software or search agents provided by Nevly; or
          </p>
        </li>
        <li>
          <p>
            interfere with security-related features of the Service, including
            by:
          </p>
          <ul>
            <li>
              <p>
                disabling or circumventing features that prevent or limit use,
                printing or copying of any content; or
              </p>
            </li>
            <li>
              <p>
                reverse engineering or otherwise attempting to discover the
                source code of any portion of the Service except to the extent
                that the activity is expressly permitted by applicable law;
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            interfere with the operation of the Service or any user’s enjoyment
            of the Service, including by:
          </p>
          <ul>
            <li>
              <p>
                uploading or otherwise disseminating any virus, adware, spyware,
                worm, or other malicious code;
              </p>
            </li>
            <li>
              <p>
                making any unsolicited offer or advertisement to another user of
                the Service;
              </p>
            </li>
            <li>
              <p>
                collecting personal information about another user or
                third-party without consent;
              </p>
            </li>
            <li>
              <p>
                interfering with or disrupting any network, equipment, or server
                connected to or used to provide the Service;
              </p>
            </li>
            <li>
              <p>
                perform any fraudulent activity including impersonating any
                person or entity, claiming a false affiliation or identify,
                accessing any other Service account without permission, or
                falsifying your age or date of birth;
              </p>
            </li>
            <li>
              <p>
                sell or otherwise transfer the access granted under these Terms;
              </p>
            </li>
            <li>
              <p>
                post or transmit any advertising, charitable requests, business
                opportunities, memberships, promotional materials or any other
                solicitation of other users to use goods or services except
                where authorized;
              </p>
            </li>
            <li>
              <p>
                copy or use personal identifying or business contact information
                about other users without permission;
              </p>
            </li>
            <li>
              <p>
                Unsolicited emails, mailings, telephone calls, or other
                communications to individuals; or
              </p>
            </li>
            <li>
              <p>
                attempt to do any of the acts described in this Section or
                assist or permit any person in engaging in any of the acts
                described in this Section.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <strong><u>COMMUNICATIONS</u></strong>
      </p>

      <p>Section 11 - Communications.</p>

      <p>Text Messaging.</p>
      <p>
        You agree that Nevly and those acting on our behalf may send you text
        (SMS) messages at the phone number you provide us. These messages may
        include operational messages about your use of the Service, as well as
        marketing messages. Text messages may be sent using an automatic
        telephone dialing system. Standard data and message rates may apply
        whenever you send or receive such messages, as specified by your
        carrier. BY PROVIDING YOUR PHONE NUMBER AND CLICKING “SIGN AND
        CONTINUE,” TO THESE TERMS OR SIGNING UP FOR THE SERVICES YOU AGREE TO
        RECEIVE CALLS AND/OR TEXT MESSAGES AT THE NUMBER YOU PROVIDE TO NEVLY.
        WE’RE REQUIRED TO INFORM YOU THAT THESE CALLS AND/OR TEXT MESSAGES MAY
        BE CONSIDERED MARKETING UNDER APPLICABLE LAW, MAY BE MADE USING AN
        AUTODIALER, AND YOUR CONSENT IS NOT A CONDITION OF PURCHASE. IF YOU WISH
        TO OPT OUT OF ALL TEXT MESSAGES FROM NEVLY, YOU CAN EMAIL
        SUPPORT@NEVLY.COM OR TEXT THE WORD “STOP” TO THE NUMBER FROM WHICH YOU
        ARE RECEIVING THE MESSAGES, HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF
        RECEIVING ALL MESSAGES MAY IMPACT YOUR USE OF THE SERVICE. You may
        continue to receive text messages for a short period while we process
        your request, including a message confirming the receipt of your opt-out
        request. Your agreement to receive marketing texts is not a condition of
        any purchase on or use of the Service.
      </p>

      <p>Email.</p>
      <p>
        We may send you promotional emails concerning our products and services,
        including from third parties. You may opt out of promotional emails by
        following the unsubscribe instructions in the promotional email itself.
      </p>

      <p>
        <strong><u>DISCLAIMERS OF WARRANTIES</u></strong>
      </p>

      <p>Section 12 - Disclaimers; No Warranties.</p>

      <p>
        YOUR ACCESS TO AND USE OF ALL OR ANY PORTION OF THE SERVICE IS ENTIRELY
        AT YOUR OWN RISK. THE SERVICE IS PROVIDED ON AN “AS IS” BASIS. TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEVLY, ITS AFFILIATES, AND
        THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS, MEMBERS, MANAGERS, PARTNERS,
        OFFICERS, EMPLOYEES, CONTRACTORS, AND OTHER AGENTS (COLLECTIVELY, “NEVLY
        PARTIES”) DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING:
        (A) WARRANTIES OF MERCHANTABILITY, QUALITY, ACCURACY, TITLE,
        NON-INTERFERENCE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE
        WITH RESPECT TO THE SERVICE; (B) WARRANTIES THAT THE SERVICE, AND ANY
        DATA PROCESSED THROUGH OR USING THE SERVICE IS SECURE, ACCURATE, FREE
        FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION; AND (C)
        WARRANTIES OR GUARANTEES THAT YOUR INVESTMENT, RISK CONTROL, RISK
        MANAGEMENT, OR RETURN OBJECTIVES, EXPECTATIONS, OR TARGETS WILL BE
        ACHIEVED. IF ANY OF THESE EXCLUSIONS DO NOT APPLY TO YOU OR ARE VOID
        WITH RESPECT TO YOU UNDER ANY APPLICABLE LAW, THEN ANY WARRANTY THAT
        CANNOT BE EXCLUDED IS LIMITED TO THE SHORTER OF: (I) 90 DAYS FROM THE
        DATE OF FIRST DELIVERY OF THE SERVICE; AND (II) THE SHORTEST PERIOD
        PERMITTED UNDER APPLICABLE LAW.
      </p>

      <p>
        <strong><u>LIMITATION OF LIABILITY</u></strong>
      </p>

      <p>Section 13 - Limitation of Liability.</p>

      <p>
        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE NEVLY
        PARTIES’ AGGREGATE LIABILITY FOR DAMAGES ARISING OUT OF OR UNDER THESE
        TERMS WILL NOT EXCEED THE GREATER OF: (1) FEES PAID BY YOU IN THE 6
        MONTHS PRECEDING THE DATE OF THE APPLICABLE CLAIM; AND (2) THE SUM OF
        US$100; AND (B) NO NEVLY PARTY WILL BE LIABLE FOR (I) ANY CONSEQUENTIAL,
        SPECIAL, INDIRECT, INCIDENTAL, OR PUNITIVE DAMAGES, EVEN IF ANY NEVLY
        PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF THOSE DAMAGES, (II) ANY
        DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET,
        ELECTRONIC COMMUNICATIONS, DATA CORRUPTION, SECURITY, LOSS OR THEFT OF
        DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, LOSS OF REVENUE, OR LOSS OF
        PROFITS OR INVESTMENT; AND (III) ANY LOSS, DAMAGE OR LIABILITY RELATED
        TO OR RESULTING FROM ANY FINANCIAL OR INVESTMENT ADVICE MADE TO YOU.
        THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
        PURPOSE OF ANY LIMITED REMEDY IN THESE TERMS.
      </p>

      <p>
        <strong><u>DISPUTE RESOLUTION</u></strong>
      </p>

      <p>Section 14 - Dispute Resolution and Arbitration.</p>

      <p>Generally.</p>
      <p>
        In the interest of resolving disputes between you and Nevly in the most
        expedient and cost-effective manner, and except as described in Section
        (1) and (2), you and Nevly agree that every dispute arising in
        connection with these Terms, the Service, and communications from us
        will be resolved by binding arbitration. Arbitration is less formal than
        a lawsuit in court. Arbitration uses a neutral arbitrator instead of a
        judge or jury, may allow for more limited discovery than in court, and
        can be subject to very limited review by courts. Arbitrators can award
        the same damages and relief that a court can award. This agreement to
        arbitrate disputes includes all claims arising out of or relating to any
        aspect of these Terms, the Service, or any communications to or from us,
        whether based in contract, tort, statute, fraud, misrepresentation, or
        any other legal theory, and regardless of whether a claim arises during
        or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT,
        BY ENTERING INTO THESE TERMS, YOU AND NEVLY ARE EACH WAIVING THE RIGHT
        TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
      </p>

      <p>Exceptions.</p>
      <p>
        Despite the provisions of Section 14 (1), nothing in these Terms will be
        deemed to waive, preclude, or otherwise limit the right of either party
        to: (a) bring an individual action in small claims court; (b) pursue an
        enforcement action through the applicable federal, state, or local
        agency if that action is available; (c) seek injunctive relief in a
        court of law in aid of arbitration; or (d) to file suit in a court of
        law to address an intellectual property infringement claim.
      </p>

      <p>Opt-Out.</p>
      <p>
        If you do not wish to resolve disputes by binding arbitration, you may
        opt out of the provisions of this Section within thirty (30) days after
        the date that you agree to these Terms by sending a letter to Nevly
        Holdings Corp., Attention: Legal Department – Arbitration Opt-Out, :609
        H Street NE, 4th Floor, Washington DC 20002 that specifies: your full
        legal name, the email address associated with your account on the
        Service, and a statement that you wish to opt out of arbitration
        (“Opt-Out Notice”). Once Nevly receives your Opt-Out Notice, this
        Section will be void and any action arising out of these Terms will be
        resolved as set forth in Section 20. The remaining provisions of these
        Terms will not be affected by your Opt-Out Notice.
      </p>

      <p>Arbitrator.</p>
      <p>
        Any arbitration between you and Nevly will be settled under the Federal
        Arbitration Act and administered by the American Arbitration Association
        (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”)
        as modified by these Terms. The AAA Rules and filing forms are available
        online at www.adr.org, by calling the AAA at +1-800-778-7879, or by
        contacting Nevly. The arbitrator has exclusive authority to resolve any
        dispute relating to the interpretation, applicability, or enforceability
        of this binding arbitration agreement.
      </p>

      <p>Notice of Arbitration; Process.</p>
      <p>
        A party who intends to seek arbitration must first send a written notice
        of the dispute to the other party by certified U.S. Mail or by Federal
        Express (signature required) or, only if that other party has not
        provided a current physical address, then by electronic mail (“Notice of
        Arbitration”). Nevly’s address for Notice is: 609 H Street NE, 4th
        Floor, Washington DC 20002. The Notice of Arbitration must: (a) describe
        the nature and basis of the claim or dispute; and (b) set forth the
        specific relief sought (“Demand”). The parties will make good faith
        efforts to resolve the claim directly, but if the parties do not reach
        an agreement to do so within 30 days after the Notice of Arbitration is
        received, you or Nevly may commence an arbitration proceeding. All
        arbitration proceedings between the parties will be confidential unless
        otherwise agreed by the parties in writing. During the arbitration, the
        amount of any settlement offer made by you or Nevly must not be
        disclosed to the arbitrator until after the arbitrator makes a final
        decision and award, if any. If the arbitrator awards you an amount
        higher than the last written settlement amount offered by Nevly in
        settlement of the dispute prior to the award, Nevly will pay to you the
        higher of: (a) the amount awarded by the arbitrator and (b) US$10,000.
      </p>

      <p>Fees.</p>
      <p>
        If you commence arbitration in accordance with these Terms, Nevly will
        reimburse you for your payment of the filing fee, unless your claim is
        for more than US$10,000, in which case the payment of any fees will be
        decided by the AAA Rules. Any arbitration hearing will take place at a
        location to be agreed upon in the county and state of your residence or
        billing address, but if the claim is for US$10,000 or less, you may
        choose whether the arbitration will be conducted: (a) solely on the
        basis of documents submitted to the arbitrator; (b) through a
        non-appearance based telephone hearing; or (c) by an in-person hearing
        as established by the AAA Rules in the county (or parish) of your
        residence or billing address. If the arbitrator finds that either the
        substance of your claim or the relief sought in the Demand is frivolous
        or brought for an improper purpose (as measured by the standards set
        forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
        fees will be governed by the AAA Rules. In that case, you agree to
        reimburse Nevly for all monies previously disbursed by it that are
        otherwise your obligation to pay under the AAA Rules. Regardless of the
        manner in which the arbitration is conducted, the arbitrator must issue
        a reasoned written decision sufficient to explain the essential findings
        and conclusions on which the decision and award, if any, are based. The
        arbitrator may make rulings and resolve disputes as to the payment and
        reimbursement of fees or expenses at any time during the proceeding and
        upon request from either party made within 14 days of the arbitrator’s
        ruling on the merits.
      </p>

      <p>No Class Actions.</p>
      <p>
        YOU AND WE BOTH AGREE THAT EITHER PARTY MAY BRING CLAIMS AGAINST THE
        OTHER ONLY IN ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
        MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
        unless we and you both agree otherwise, the arbitrator may not
        consolidate more than one person’s claims and may not otherwise preside
        over any form of a representative or class proceeding.
      </p>

      <p>Modifications to this Arbitration Provision.</p>
      <p>
        If Nevly makes any future change to this arbitration provision, other
        than a change to Nevly’s address for Notice of Arbitration, you may
        reject the change by sending us written notice within 30 days of the
        change to Nevly’s address for Notice of Arbitration, in which case your
        account with Nevly will be immediately terminated and this arbitration
        provision, as in effect immediately prior to the changes you rejected
        will survive.
      </p>

      <p>Enforceability.</p>
      <p>
        If Sub-Section (6) or the entirety of this Section is found to be
        unenforceable, or if Nevly receives an Opt-Out Notice from you, then the
        entirety of this Section 14 will be null and void and, in that case, the
        exclusive jurisdiction and venue described in Section 18 will govern any
        action arising out of or related to these Terms.
      </p>

      <p>
        <strong><u>INDEMNIFICATION</u></strong>
      </p>

      <p>Section 15 - Indemnification.</p>

      <p>
        You will defend, indemnify, and hold the Nevly Parties harmless against
        any claim, demand, suit, or proceeding made or brought against any Nevly
        Party by a third-party and any related expenses (including reasonable
        attorneys’ fees), losses, damages, liabilities, demands, charges, fees,
        or claims of any kind or nature whatsoever (collectively, “Losses”)
        arising from, relating to, or otherwise based on: (a) any breach of
        these Terms by you; (b) any inaccuracy or omission of any information,
        data, or other materials provided to Nevly by you or otherwise
        transmitted to or through the Service; (c) any action for securities law
        violations instituted by you that is finally resolved by judgment
        against you. Your obligations under this Section are in addition to any
        liability you may otherwise have under these Terms.
      </p>

      <p>
        <strong><u>CALIFORNIA RESIDENTS</u></strong>
      </p>

      <p>Section 16 - Notice to California Residents.</p>

      <p>
        If you are a California resident, under California Civil Code Section
        1789.3, you may contact the Complaint Assistance Unit of the Division of
        Consumer Services of the California Department of Consumer Affairs in
        writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California
        95834, or by telephone at (800) 952-5210 in order to resolve a complaint
        regarding the Service or to receive further information regarding use of
        the Service.
      </p>

      <p>
        <strong><u>SUPPORT SERVICE</u></strong>
      </p>

      <p>Section 17 - No Support.</p>

      <p>
        We are under no obligation to provide support for the Service. In
        instances where we may offer support, the support will be subject to our
        relevant policies.
      </p>

      <p>
        <strong><u>GOVERNING LAW AND VENUE</u></strong>
      </p>

      <p>Section 18 - Governing Law and Venue.</p>

      <p>
        These Terms are governed by the laws of the State of Delaware, without
        reference to its conflict of laws principles. Any dispute between you
        and Nevly regarding these Terms will be subject to the exclusive
        jurisdiction of the federal and state courts located in Washington DC.
      </p>

      <p>
        <strong><u>NO ASSIGNMENT</u></strong>
      </p>

      <p>Section 19 - No Assignment.</p>

      <p>
        You may not assignment your rights under these Terms without our prior
        written consent.
      </p>

      <p>
        <strong><u>NO WAIVER</u></strong>
      </p>

      <p>Section 20 - No Waiver.</p>

      <p>
        Nothing contained in these Terms constitutes a waiver by you of any of
        your legal rights under U.S. federal securities laws or any other laws
        that are not permitted to be contractually waived.
      </p>

      <p>
        <strong><u>AGREEMENT</u></strong>
      </p>

      <p>Section 21 - Agreement.</p>

      <p>
        These Terms of Service are the entire agreement between you and Nevly
        and supersedes any other communications with respect to the Service.
      </p>

      <div class="read-more" *ngIf="!hideReadMore">
        <button
          class="btn custom-btn btn-bg text-white d-none d-md-block d-lg-block d-xl-block"
          (click)="hideReadMore = true"
        >
          Read more
        </button>
        <img
          class="img-fluid d-block d-md-none d-lg-none d-xl-none mx-auto cursor"
          (click)="hideReadMore = true"
          src="../../../assets/img/readmore.png"
        />
      </div>
    </div>
  </section>
</div>
