<footer>

<div class="section_footer">
  <div>
    <img  style="margin-bottom:85px" src="../../../assets/img/logo_bottom.png" />
  </div>

  <div class="bottom_box_link" style="width: 166px;">
    <p class="bbl_title" >Explore<br></p>
    <p  class="bbl_ops">
      <a routerLink="/about"><span>About Us<br></span></a> 
      <a  (click)="pageView('#services')" data-action="url" data-params="false" href="https://get.nevlymoney.com/credit-repair-v2/#lp-pom-block-46" target="_self"><span>Services<br></span></a> 
      <a  (click)="pageView('#pricing')" data-action="url" data-params="false" href="https://get.nevlymoney.com/credit-repair-v2/#diff-chart" target="_self"><span>Pricing</span></a>
    </p>
  </div>
  <div class="bottom_box_link" style="left: 275px;width: 278px;">
    <p class="bbl_title" >Policies<br></p>
    <p  class="bbl_ops">
      <a routerLink="/terms-condition"><span>Terms & Conditions<br></span></a> 
      <a routerLink="/privacy-policy"><span>Privacy Policy</span></a>
    </p>
  </div>
  
  <div class="bottom_box_link" style="left: 656px;width: 255px;">
    <p class="bbl_title" >Products<br></p>
    <p  class="bbl_ops">
      <a routerLink="/faq" ><span>FAQ's<br></span></a> 
      <a (click)="goToUrl('/#/#reviews')" style="cursor: pointer;"><span>Reviews</span></a>
      <!--a routerLink fragment="reviews" (click)="goToUrl('/#reviews')"  (click)="pageView('#reviewSection')" ><span>Reviews</span></a-->
    </p>
  </div>
  <div class="bottom_box_link" style="left: 997px;width: 203px;">
    <p class="bbl_title" >Support<br></p>
    <p  class="bbl_ops">
      <a href="" target="_self"><span>Contact us</span></a>
    </p>
  </div>
  <div class="bottom_box_link" style="left: 997px;width: 203px;top:300px">
    <p class="bbl_title" >Follow us<br></p>
    <p  class="bbl_ops" style="padding-bottom:40px">
      <a href="https://www.instagram.com/thenevlyapp/" target="_self"><img src="./../../assets/img/instagram.logo.png"></a>
      &nbsp;<a href="https://www.facebook.com/nevlyapp" target="_self"><img src="./../../assets/img/facebook.logo.png"></a>
    </p>
  </div>

</div>

</footer>
