<app-header></app-header>
<main id="main-content" #mainContent>
  <router-outlet></router-outlet>
</main>

<div class="section_dance">
  <div style="position: relative; width: 1200px; height: 442px">
    <div class="sdance_text">
      <h3>
        <span
          ><strong
            >50% off first months. <br />Earn $15 per month <br />when you refer
            a friend.&nbsp;</strong
          ></span
        >
      </h3>
      <a href="https://app.nevlymoney.com/#/register" class="join_button"
        ><span class="jlabel">Get Started</span></a
      >
    </div>
  </div>
</div>

<app-footer></app-footer>
