import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AboutComponent } from './pages/about/about.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { PersonalInformationComponent } from './pages/personal-information/personal-information.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //children: [{ path: '', component: HomeComponent }],
    data: {
      title: 'Nevly - Home',
      description: "Nevly is website provide the credit card scores."
    }
  },
  {
    path: 'about',
    component: HomeComponent,
    //children: [{ path: '', component: AboutComponent }],
    data: {
      title: 'Nevly - Home',
      description: "Nevly is website provide the credit card scores."
    }
  },

  {
    path: 'privacy-policy',
    component: MainLayoutComponent,
    children: [{ path: '', component: PrivacyPolicyComponent }],
    data: {
      title: 'Nevly - Privacy Policy',
      description: "Nevly is website provide the credit card scores."
    }
  },
  {
    path: 'terms-condition',
    component: MainLayoutComponent,
    children: [{ path: '', component: TermsConditionsComponent }],
    data: {
      title: 'Nevly - Terms Condition',
      description: "Nevly is website provide the credit card scores."
    }
  },
  {
    path: 'faq',
    component: MainLayoutComponent,
    children: [{ path: '', component: FaqComponent }],
    data: {
      title: 'Nevly - FAQ',
      description: "Nevly is website provide the credit card scores."
    }
  },
  {
    path: 'cookie',
    component: MainLayoutComponent,
    children: [{ path: '', component: CookiePolicyComponent }],
    data: {
      title: 'Nevly - Nevly Cookie Policy',
      description: "Nevly is website provide the credit card scores."
    }
  },
  {
    path: 'personal-information',
    component: MainLayoutComponent,
    children: [{ path: '', component: PersonalInformationComponent }],
    data: {
      title: 'Nevly - Peronal Information',
      description: "Nevly is website provide the credit card scores."
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
