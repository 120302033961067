<div class="my-5 pt-5 mx-auto container">
  <section class="mx-3 section-terms-condition-1">
    <h1 class="display-1">Privacy Policy</h1>
    <div
      class="content"
      [ngStyle]="{
        height: hideReadMore ? '100%' : '1000px',
        overflow: hideReadMore ? 'auto' : 'hidden'
      }"
      [@fadeInOut]>
      <!--a routerLink="/personal-information" class="link text-decoration-none link-top d-flex align-items-center">What does Nevly do with your personal information? <img src="../../../assets/img/link.png" class="mx-2"/></a -->
      <strong>About Us </strong>
      <p>
        We are Nevly, a financial technology company. The Privacy Policy covers
        Nevly’s Nevly Money services in the United States.
      </p>
      <p>
        WE (AND OTHERS ACTING ON OUR BEHALF) ARE COMMITTED TO PROTECTING AND
        RESPECTING YOUR PRIVACY.
      </p>
      <div>
        <p>We will:</p>
        <ul>
          <li><p>always keep your information safe and private;</p></li>
          <li><p>never sell your information; and</p></li>
          <li>
            <p>
              allow you to manage and review your marketing choices at any time.
            </p>
          </li>
        </ul>
      </div>
      <strong><p>Why do I need to read this notice?</p></strong>

      <p>We will collect your personal information when you use:</p>
      <ul>
        <li>
          <p>
            our website at
            <a href="https://nevlymoney.com/">https://nevlymoney.com</a>;
          </p>
        </li>
        <li><p>the Nevly app; or</p></li>
        <li>
          <p>
            any of the services you can get access to through the Nevly Money
            app or website (our "Services”).
          </p>
        </li>
      </ul>

      <strong
        ><p><u>THIS POLICY CONTAINS IMPORTANT INFORMATION</u></p></strong
      >

      <p>
        This document explains what information we collect, how we use it, and
        your rights if you want to change how we use your personal information.
      </p>
      <p>
        If you have concerns about how we use your personal information, you can
        contact us at privacy@nevly.com.
      </p>
      <p>
        This Policy is originally written in and governed by the English
        language. If this text is translated into another language and there is
        a conflict between the English and non-English versions, the English
        text will prevail.
      </p>

      <strong
        ><p><u>YOUR PERSONAL INFORMATION</u></p></strong
      >

      <strong><p>What information do you collect about me?</p></strong>

      <ul>
        <li>
          <p>
            The list below explains what personal information we collect and
            use.
          </p>
          <ul>
            <li>
              <p>Information you give us</p>
              <ul>
                <li>
                  <p>We collect information you provide when you:</p>
                  <ul>
                    <li><p>fill in any forms;</p></li>
                    <li><p>correspond with us;</p></li>
                    <li><p>register to use the Nevly app;</p></li>
                    <li><p>open an account or use any of our services;</p></li>
                    <li>
                      <p>
                        take part in online discussions, surveys or promotions;
                      </p>
                    </li>
                    <li>
                      <p>
                        speak with a member of our customer support team (either
                        on the phone or through the Nevly app);
                      </p>
                    </li>
                    <li><p>enter a promotion; or</p></li>
                    <li><p>contact us for other reasons.</p></li>
                  </ul>
                </li>
                <li>
                  <p>We will collect the following information:</p>
                  <ul>
                    <li><p>your name, address, and date of birth;</p></li>
                    <li>
                      <p>
                        your email address, phone number and details of the
                        device you use (for example, your phone, computer or
                        tablet);
                      </p>
                    </li>
                    <li>
                      <p>
                        your username, password and other registration
                        information;
                      </p>
                    </li>
                    <li>
                      <p>
                        details of your bank account, including the account
                        number, sort code and ABA routing number or SWIFT code;
                      </p>
                    </li>
                    <li>
                      <p>
                        details of your debit cards and credit cards, including
                        the card number, expiration date and CVC (the last three
                        digits of the number on the back of the card);
                      </p>
                    </li>
                    <li>
                      <p>
                        identification documents (for example, your passport or
                        driving license number), copies of any documents you
                        have provided for identification purposes, a photograph
                        of you, and any other information you provide to prove
                        you are eligible to use our services;
                      </p>
                    </li>
                    <li>
                      <p>
                        records of our discussions, if you contact us or we
                        contact you; and
                      </p>
                    </li>
                    <li><p>your photo (if you upload one).</p></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Information from your device whenever you use our website or the
                Nevly Money app.
              </p>
              <ul>
                <li>
                  <p>We collect the following information:</p>
                  <ul>
                    <li>
                      <p>
                        technical information, including the internet protocol
                        (IP) address used to connect your computer to the
                        internet, your log-in information, the browser type and
                        version, the time-zone setting, the operating system and
                        platform, the type of device you use, a unique device
                        identifier (for example, your device's IMEI number, the
                        MAC address of the device's wireless network interface,
                        or the mobile phone number used by the device), mobile
                        network information, your mobile operating system, the
                        type of mobile browser you use and so on;
                      </p>
                    </li>
                    <li>
                      <p>
                        information about your visit, including the links you
                        have clicked on, through and from our site (including
                        date and time), services you viewed or searched for,
                        page response times, download errors, length of visits
                        to certain pages, page interaction information (such as
                        scrolling and clicks), and methods used to browse away
                        from the page;
                      </p>
                    </li>
                    <li>
                      <p>
                        information on transactions (for example, payments into
                        and out of your credit builder card account), including
                        the date, time, amount, beneficiary details, details of
                        the merchant or ATMs associated with the transaction;
                        and
                      </p>
                    </li>
                    <li>
                      <p>
                        information stored on your device, including if you give
                        us access to contact information from your address book,
                        log-in information, photos, videos or other digital
                        content, check-ins (sometimes, we call this content
                        information). The Nevly Money app will regularly collect
                        this information in order to stay up to date.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>Information about your location</p>
              <ul>
                <li>
                  <p>
                    We collect information from third parties, such as credit
                    reference agencies, fraud-prevention agencies and partners
                    who help us to provide our services. This includes your
                    credit record, information to help us check your identity,
                    and information relating to your transactions.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>Information from social media</p>
              <ul>
                <li>
                  <p>
                    If you allow us to, we will collect information such as
                    friends lists from Facebook or similar information from
                    other online accounts. If you've asked us to, we'll use your
                    Facebook profile to confirm your identity as part of our
                    Know Your Customer (KYC) process, (which is the process of
                    how we verify you as a customer).
                  </p>
                </li>
              </ul>
            </li>
            <li><p>Information from third parties</p></li>
          </ul>
        </li>
      </ul>

      <p>How do you use my information?</p>

      <ul>
        <li>
          <p>Keeping to our contracts and agreements with you:</p>
          <ul>
            <li>
              <p>
                We need certain personal information to provide our services and
                cannot provide them without this information.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Legal obligations:</p>
          <ul>
            <li>
              <p>
                In some cases, we have a legal responsibility to collect and
                store your personal information (for example, under
                money-laundering laws we must hold certain information about our
                customers).
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Providing our services:</p>
          <ul>
            <li>
              <p>
                Whenever you apply for a product or service, we will use your
                personal information to check your identity (as part of our KYC
                process) and decide whether or not to approve your application.
              </p>
            </li>
            <li>
              <p>
                If you are already a Nevly Money customer, we use your personal
                information to meet our obligations relating to any transactions
                you make (for example, making payments into and out of your
                Nevly Money Credit Builder Card account, withdrawing cash or
                making payments with your Nevly Credit Builder Card). We also
                use your personal information to give you details of our
                products and services.
              </p>
            </li>
            <li>
              <p>
                We also use your personal information to contact you by phone
                and provide you with customer support services. We may record
                these calls, but only for internal training and quality-control
                purposes.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <p>The types of information we use are:</p>

      <ul>
        <li>
          <p>information you have given us; and</p>
        </li>
        <li>
          <p>
            information from third parties (as explained in the "What
            information do you collect about me?" section above).
          </p>
        </li>
      </ul>

      <strong
        ><p>
          <u
            >HERE'S AN EXAMPLE OF HOW WE USE YOUR PERSONAL INFORMATION TO
            PROVIDE OUR SERVICES:</u
          >
        </p></strong
      >

      <ul>
        <li>
          <p>
            If you apply for a credit product, we (or our lending partner) will
            carry out a credit check to better understand your financial
            circumstances and repayment history.
          </p>
          <ul>
            <li>
              <p>Our legal basis for collecting your information includes:</p>
              <ul>
                <li>
                  <p>keeping to contracts and agreements between you and us;</p>
                </li>
                <li>
                  <p>
                    legitimate interests (we will be efficient about how we meet
                    our obligations, and we want to provide you with a good
                    service); and/or
                  </p>
                </li>
                <li><p>legal obligations.</p></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>Protecting against fraud.</p>
          <ul>
            <li>
              <p>
                We use your personal information to check your identity to
                protect against fraud, comply with financial crime laws and to
                confirm that you are eligible to use our services. We also use
                it to help us better understand your financial circumstances and
                manage fraud risks related to your Nevly account
              </p>
            </li>
            <li>
              <p>The types of information we use are:</p>
              <ul>
                <li><p>information you have provided;</p></li>
                <li><p>information from your device;</p></li>
                <li><p>location information; and/or</p></li>
                <li><p>information from third parties.</p></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Marketing and providing new products and services that might
            interest you.
          </p>
          <ul>
            <li>
              <p>We use your personal information to do the following:</p>
              <ul>
                <li>
                  <p>
                    provide you with information about other goods and services
                    we offer that are similar to those you have already used or
                    asked about;
                  </p>
                </li>
                <li>
                  <p>
                    provide you with information about our goods or services,
                    and our partner's promotions or offers, which we think you
                    might be interested in. To help us do so, we may use
                    information we hold about you in order to better understand
                    your interests. You can opt out of this by using the in-app
                    help service or by emailing privacy@nevly.com;
                  </p>
                </li>
                <li>
                  <p>
                    if you agree, allow our partners and other organizations to
                    provide you with information about their goods or services;
                  </p>
                </li>
                <li>
                  <p>
                    measure or understand the effectiveness of our advertising,
                    and provide relevant advertising to you; or
                  </p>
                </li>
                <li>
                  <p>
                    process applications for products and services available
                    through us and make decisions about whether to approve
                    applications.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Remember, you can ask us to stop sending you marketing
                information by adjusting your marketing choices within the app.
              </p>
            </li>
            <li>
              <p>The types of information we use are:</p>
              <ul>
                <li><p>information you have provided;</p></li>
                <li><p>information from your device;</p></li>
                <li><p>location information; and/or</p></li>
                <li><p>information from third parties.</p></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <strong
        ><p>
          <u
            >HERE'S AN EXAMPLE OF HOW WE USE YOUR PERSONAL INFORMATION FOR
            MARKETING:</u
          >
        </p></strong
      >

      <ul>
        <li>
          <p>
            If you are a Nevly Money customer, we may contact you about optional
            extras or promotional offers. We may use information we gather about
            you through your use of our services to tailor these offers to you
          </p>
        </li>
        <li><p>To keep our services up and running.</p></li>
        <li>
          <p>
            We use your personal information to manage our website and the Nevly
            Money app, (including troubleshooting, data analysis, testing,
            research, statistical and survey purposes), and to make sure that
            content from our website is presented in the most effective way for
            you and your device. For more information, please see our
            <a href="https://nevly.com/cookie-policy/" target="_blank"
              >Cookies Policy</a
            >.
          </p>
        </li>
        <li>
          <p>
            We also use your personal information to allow you to take part in
            interactive features of our services, to tell you about changes to
            our services, and to help keep our website and the Nevly app safe
            and secure.
          </p>
        </li>
        <li>
          <p>The types of information we use are:</p>
          <ul>
            <li><p>information you have provided; and/or</p></li>
            <li><p>information from your device.</p></li>
          </ul>
        </li>
      </ul>

      <strong>
        <p>
          <u
            >HERE'S AN EXAMPLE OF HOW WE USE YOUR PERSONAL INFORMATION TO RUN
            OUR SERVICES:</u
          >
        </p>
      </strong>

      <ul>
        <li>
          <p>
            If any changes we make to our services affect you, we'll normally
            contact you using the email address you gave us when you signed up,
            or through the Nevly app, to tell you about the changes.
          </p>
        </li>
        <li>
          <p>Helping with customer service</p>
          <ul>
            <li>
              <p>
                We use your personal information to provide customer service,
                help you complete transactions, and to add extra functions in
                order to provide a better experience.
              </p>
            </li>
            <li>
              <p>The types of information we use are:</p>
              <ul>
                <li>information you have provided;</li>
                <li>information from your device; and/or</li>
                <li>location information.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>Advertising based on location</p>
          <ul>
            <li>
              <p>
                We use your information to provide relevant advertising to you
                (for example, information on nearby merchants).
              </p>
            </li>
            <li>
              <p>The types of information we use are:</p>
              <ul>
                <li>information you have provided; and</li>
                <li>location information.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p>The legal basis for collecting your information includes:</p>

      <ul>
        <li>
          <p>keeping to contracts and agreements between you and us;</p>
        </li>
        <li>
          <p>
            legitimate interests (to develop and market our obligations and keep
            to regulations that apply to us); and/or
          </p>
        </li>
        <li>
          <p>
            consent (to track you when you have location services switched on).
          </p>
        </li>
      </ul>

      <p>
        Meeting our legal obligations, enforcing our rights and other legal uses
      </p>

      <ul>
        <li>
          <p>We may need to share information about you:</p>
          <ul>
            <li>
              <p>
                with other entities (for example, fraud prevention vendors);
              </p>
            </li>
            <li>
              <p>
                if it is necessary to meet our legal obligations or in
                connection with legal claims; and/or
              </p>
            </li>
            <li>
              <p>to help detect or prevent crime.</p>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Meeting our legal obligations, enforcing our rights and other legal uses
      </p>

      <p>The types of information we use are:</p>

      <ul>
        <li>
          <p>information you have provided;</p>
        </li>
        <li>
          <p>information from your device;</p>
        </li>
        <li>
          <p>location information;</p>
        </li>
        <li>
          <p>information from third parties; and/or</p>
        </li>
        <li>
          <p>social media information.</p>
        </li>
      </ul>

      <p>How do you use my information for marketing?</p>

      <p>
        If you sign up to our services, we will assume you want us to contact
        you by post, email and phone (including text message) with offers and
        promotions. We may use the information we have collected about you in
        order to tailor our offers to you.
      </p>

      <p>
        You can adjust your preferences or tell us you don't want to hear from
        us, at any time. Just click on the unsubscribe links on any marketing
        message we send you.
      </p>

      <p>
        We won't pass your details on to any organizations outside the Nevly
        group of companies for their marketing purposes without your consent.
        You can find out more in the "Do you share my information with anyone
        else?" section.
      </p>

      <p>YOUR RIGHTS</p>

      <p>What are my rights?</p>

      <ul>
        <li>
          <p>
            You have the right to be informed about how we use your personal
            information.
          </p>
        </li>

        <li>
          <p>
            We provide this privacy notice to explain how we use your personal
            information.
          </p>
        </li>

        <li>
          <p>
            You can ask to see the personal information we hold about you (this
            is called making a data subject access request, or DSAR for short).
          </p>
          <ul>
            <li>
              <p>
                You can also ask for information about what information we
                process about you and why. If you make a DSAR, we will provide a
                copy of the personal information we hold about you.
              </p>
              <p>
                This right is subject to exceptions. For example, we can only
                provide you with information as required and permitted under the
                Law and we cannot provide you with personal information about
                other people.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            You can ask us to correct your personal information if you think
            it's wrong.
          </p>
        </li>

        <li>
          <p>
            You can have incomplete or inaccurate information corrected. Before
            we update your file, we may need to check the accuracy of the new
            information you have provided.
          </p>
        </li>

        <li>
          <p>You can ask us to delete your personal information.</p>
          <ul>
            <li>
              <p>You can ask us to delete your personal information if:</p>
              <ul>
                <li>
                  <p>There's no good reason for us to continue using it;</p>
                </li>
                <li>
                  <p>
                    You gave us consent to use the information and you have now
                    withdrawn that consent;
                  </p>
                </li>
                <li>
                  <p>You have objected to us using the information;</p>
                </li>
                <li>
                  <p>We have used the information unlawfully; or</p>
                </li>
                <li>
                  <p>The law requires us to delete the information.</p>
                  <ul>
                    <li>
                      <p>
                        Just to let you know, we may not be able to delete some
                        of your information. We will keep certain customer
                        information for at least six years (we've explained this
                        in more detail below). If you've closed your Nevly Money
                        account, we may not be able to delete your entire file
                        because these regulatory responsibilities take priority.
                        We will always let you know if we can't delete your
                        information.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>
            You can object to us processing your personal information for
            marketing purposes.
          </p>
        </li>

        <li>
          <p>
            You can tell us to stop using your personal information for
            marketing.
          </p>
        </li>

        <li>
          <p>
            You can object to us processing other information (if we are using
            it for legitimate interests).
          </p>
        </li>

        <li>
          <p>
            If our legal basis for using your personal information is
            “legitimate interests” and you disagree with us using it, you can
            object. However, if there is an overriding reason why we need to use
            the information (for example, legal reasons), we will not accept
            your request.
          </p>
        </li>

        <li>
          <p>
            If you object to us using information which we need in order to
            provide our services, we may need to close your account as we won’t
            be able to provide the services.
          </p>
        </li>

        <li>
          <p>
            You can ask us to restrict how we use your personal information.
          </p>
        </li>

        <li>
          <p>You can ask us to suspend using your personal information if:</p>
          <ul>
            <li><p>You want us to investigate whether it is accurate;</p></li>
            <li>
              <p>
                Our use of the information is unlawful but you do not want us to
                delete it;
              </p>
            </li>
            <li>
              <p>
                We no longer need the information, but you want us to continue
                holding it for you in connection with a legal claim; or
              </p>
            </li>
            <li>
              <p>
                You have objected to us using your information (see above), but
                we need to check whether we have an overriding reason to use it.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>You can ask us to transfer personal information to you.</p>
          <ul>
            <li>
              <p>
                If we can, and are allowed to do so under regulatory
                requirements, we will provide your personal information in a
                structured, commonly used, machine-readable format.
              </p>
            </li>
          </ul>
        </li>

        <li>
          <p>You can withdraw your consent.</p>
          <ul>
            <li>
              <p>
                If you have given us any consent we need to use your personal
                information, you can withdraw your consent at any time by
                sending an email to privacy@nevly.com. (Note, it will have been
                lawful for us to use the personal information up to the point
                you withdrew your consent).
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <p>How do I exercise my rights?</p>

      <p>
        To exercise any of your rights set out in the previous section, you can
        contact us by sending us an email at privacy@nevly.com.
      </p>

      <ul>
        <li>
          <p>
            Nevly will verify your identity before it fulfills your request. We
            may do that in different ways:
          </p>
          <ul>
            <li>
              <p>
                We may require government-issued identification documents and a
                recent photograph.
              </p>
            </li>
            <li>
              <p>You may also be required to verify your email address.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Before Nevly can produce personal Information, Nevly may ask you to
            submit a signed declaration attesting to your identity under penalty
            of perjury.
          </p>
        </li>
        <li>
          <p>
            Depending on your existing relationship with Nevly, Nevly may ask
            you to provide identifying information you previously provided to
            Nevly for verification.
          </p>
        </li>
        <li>
          <p>
            You can designate an authorized agent to make a request on your
            behalf. Nevly will ask you to provide a written authorization or
            power of attorney. Please reach out to privacy@nevly.com to process
            an authorization.
          </p>
        </li>
        <li>
          <p>
            Nevly will not discriminate against you in any way because you
            exercise any rights conferred by this Privacy Notice.
          </p>
        </li>
        <li>
          <p>
            If you are unhappy with how we have handled your personal
            information you can file a formal complaint by sending us an email
            to complaints@nevly.com
          </p>
        </li>
      </ul>

      <p>Do you share my information with anyone else?</p>

      <ul>
        <li>
          <p>NEVLY GROUP COMPANIES</p>
          <ul>
            <li>
              <p>
                We share your personal information within the Nevly group of
                companies in order to provide you with the best service.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <p>SUPPLIERS</p>

      <ul>
        <li>
          <p>
            The list below explains which suppliers we normally share your
            personal information with.
          </p>
          <ul>
            <li>
              <p>
                Suppliers who provide us with IT, payment and delivery services
              </p>
              <ul>
                <li>
                  <p>To help us provide our services to you</p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Our banking and financial services partners and payments
                networks, including Visa and Mastercard.
              </p>
              <ul>
                <li>
                  <p>
                    To help us provide our services to you - this includes
                    banking and banking intermediaries providers.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>Card manufacturing, personalization and delivery companies</p>
              <ul>
                <li>
                  <p>
                    To create and deliver your personalized Nevly Credit Builder
                    Card.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p>ADVERTISERS</p>

      <ul>
        <li>
          <p>To promote our services (we provide anonymous information only)</p>
          <ul>
            <li>
              <p>Analytics providers and search information providers</p>
              <ul>
                <li>
                  <p>To help us improve our website</p>
                </li>
              </ul>
            </li>
            <li>
              <p>Customer service providers, survey providers and developers</p>
              <ul>
                <li>
                  <p>To help us to provide our services to you</p>
                </li>
              </ul>
            </li>
            <li>
              <p>Communications services providers</p>
              <ul>
                <li>
                  <p>
                    To help us send you emails, push notifications and text
                    messages
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p>PARTNERS WHO HELP TO PROVIDE OUR SERVICES</p>

      <ul>
        <li>
          <p>
            We may share your personal information with our partners in order to
            provide you with certain services you have asked us for.
          </p>
        </li>
      </ul>

      <strong
        ><p>
          <u>
            HERE'S AN EXAMPLE OF WHEN WE MIGHT SHARE YOUR INFORMATION WITH OUR
            PARTNERS:
          </u>
        </p></strong
      >

      <ul>
        <li>
          <p>
            If you have asked for rent reporting services, we will share
            relevant information to the provider of rent reporting services.
          </p>
        </li>
        <li>
          <p>
            We will only share your personal information in this way to provide
            you with the relevant service.
          </p>
        </li>
        <li>
          <p>
            From time to time, we may work with other partners to offer you
            co-branded services or promotional offers, and we will share some of
            your personal information with those partners. We will always get
            your consent before sharing your information for these purposes. You
            can withdraw your consent at any time by contacting us.
          </p>
        </li>
        <li>
          <p>
            Our partners will have their own privacy notice explaining how they
            use your personal information. It's important that you read those
            privacy notices as well.
          </p>
        </li>
      </ul>

      <p>FOR LEGAL REASONS</p>

      <ul>
        <li>
          <p>
            We may share your personal information with our partners in order to
            provide you with certain services you have asked us for.
          </p>
        </li>
      </ul>

      <strong
        ><p>
          <u>
            HERE'S AN EXAMPLE OF WHEN WE MIGHT SHARE YOUR PERSONAL INFORMATION
            FOR LEGAL REASONS: :
          </u>
        </p></strong
      >

      <p>
        If you give us false or inaccurate information and we identify fraud, we
        will let fraud-prevention agencies know. Law-enforcement agencies may
        request and use this information.
      </p>

      <strong
        ><p>
          <u>
            WE MAY ALSO NEED TO SHARE YOUR PERSONAL INFORMATION WITH OTHER
            THIRD-PARTY ORGANIZATIONS:
          </u>
        </p></strong
      >

      <ul>
        <li>
          <p>if we have to do so under any law or regulation;</p>
        </li>
        <li>
          <p>if we sell our business;</p>
        </li>
        <li>
          <p>in connection with criminal or fraud investigations;</p>
        </li>
        <li>
          <p>to enforce our rights (and those of customers or others); or</p>
        </li>
        <li>
          <p>in connection with legal claims.</p>
        </li>
      </ul>

      <p>
        Nevly does not sell your personal information to third parties. Nevly
        has both used third parties to collect personal information and has
        disclosed personal information to third parties to accomplish the
        business purposes described above.
      </p>

      <p>CONSUMER PRIVACY NOTICE</p>

      <p>
        The Consumer Privacy Notice details our privacy and security practices
        regarding our relationship with you and provides instructions on how to
        limit the sharing of your information.
      </p>

      <p>NEVLY MONEY AND YOUR PERSONAL INFORMATION</p>

      <p>Will my information go outside the United States?</p>

      <p>No it will not.</p>

      <p>
        We will take all reasonable steps to make sure that your personal
        information is handled securely and in line with this privacy notice and
        data protection laws.
      </p>

      <p>
        If you would like more information, please contact us through the Nevly
        app or by sending an email to privacy@nevly.com.
      </p>

      <p>How do you protect my personal information?</p>

      <ul>
        <li>
          <p>We store your information on our secure servers.</p>
        </li>
        <li>
          <p>
            If you use a password for the Nevly Money app or our website, you
            will need to keep this password confidential. Please do not share it
            with anyone. Unfortunately, providing information online is not
            completely secure. Although we will do our best to protect your
            personal information, we cannot guarantee that all information you
            provide through the Nevly Money app or our website will be secure.
            Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorized
            access.
          </p>
        </li>
      </ul>

      <p>How long will you keep my personal information for?</p>

      <ul>
        <li>
          <p>
            Under anti-money laundering laws, we will hold information about you
            and your transactions for a minimum of five years. Generally, we
            will not hold your personal information for more than six years
            after our business relationship with you has ended, unless we need
            to hold it for longer because of a potential or ongoing claim,
            suspected fraud, or another legal reason.
          </p>
        </li>
      </ul>

      <p>How will you keep me updated on how you use my information?</p>

      <ul>
        <li>
          <p>
            If we change the way we use your personal information, we will
            update this notice and, if appropriate, let you know by text
            message, by email, through the Nevly Money app or through our
            website.
          </p>
        </li>
      </ul>

      <p>Cookies</p>

      <ul>
        <li>
          <p>
            We use cookies to analyze how you use our website. Please read the
            Cookies Policy for more information about cookies.
          </p>
        </li>
        <li>
          <p>
            To download this page, press “command + p” (on a Mac operating
            system), and “control + p” (on a Windows operating system).
          </p>
        </li>
      </ul>

      <p>This Policy was last updated on July 24, 2023.</p>

      <p>
        We are incorporated in Delaware and have our registered office at A
        Registered Agent, Inc., 8 The Green, Suite A, Dover, Kent DE, 19901. Our
        US headquarters is based in Washington DC. Nevly Money provides services
        only to U.S. residents.
      </p>

      <div class="read-more" *ngIf="!hideReadMore" [@fadeInOut]>
        <button
          class="btn custom-btn btn-bg text-white d-none d-md-block d-lg-block d-xl-block"
          (click)="hideReadMore = true">
          Read more
        </button>
        <img
          class="img-fluid d-block d-md-none d-lg-none d-xl-none mx-auto cursor"
          (click)="hideReadMore = true"
          src="../../../assets/img/readmore.png" />
      </div>
    </div>
  </section>
</div>
