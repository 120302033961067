import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModal, HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../layout/layout.module';
import { FaqComponent } from './faq/faq.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    CookiePolicyComponent,
    PersonalInformationComponent,
    CustomModal
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    RouterModule.forChild([]),
    LayoutModule,
    NgbModule,
    CarouselModule ,
    ReactiveFormsModule,
    MatIconModule
  ],
})
export class PagesModule { }
