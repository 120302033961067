<div class="about-wrapper">
  <div class="p-0 about-section-1 px-1 mx-auto">
    <div class="row py-5 g-0">
      <div class="col-md-5 col-sm-12 pt-5">
        <div class="d-flex flex-column justify-content-center h-100 ms-4 ms-md-5 ms-lg-5 ms-xl-5 py-3">
          <h1 class="display-1 fw-300">About Nevly</h1>
          <p class="fs-24 fw-400 d-none d-md-block d-lg-block d-xl-bloc">
            Nevly is a technology company founded with a 
            relentless focus on a mission to help people improve their financial
            health and achieve financial freedom.
          </p>
        </div>
      </div>
      <div class="col-md-7 col-sm-12 img-hero pt-0 pt-md-5 pt-lg-5 pt-xl-5 px-0">
        <div class="img-about d-flex justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center h-100 py-3">
          <img class="img-fluid d-none d-md-block d-lg-block d-xl-block" src="../../../assets/img/about-1.webp" style="max-width: 70% !important;"/>
          <img class="img-fluid d-block d-md-none d-lg-none d-xl-none" src="../../../assets/img/about-mobile-1.webp" style="max-width: 70% !important;"/>
        </div>
        <p class="mb-5 d-block d-md-none d-lg-none d-xl-none">
          Nevly is a technology company founded with a relentless focus on a
          mission to help people improve their financial health and achieve
          financial freedom.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid g-0 p-0 about-section-2">
    <div class="container h-100">
      <div class="row h-100 m-0">
        <div
          class="inner-section-1 col-sm-12 d-block d-md-none d-lg-none d-xl-none"
        >
          <p class="sub-title">Club Campaign</p>
          <h3>The 700 Club Campaign</h3>

          <p class="py-5 mt-3">
            We're launching the 700 Club Campaign so you can get a head start on
            your journey to do money different. We've bundled the most expansive
            set of tools in the market today to allow you to dramatically
            improve your credit score for a fraction of what those other guy's
            charge for initiation fees.
          </p>
        </div>
        <div
          class="col-md-12 col-sm-12 text-center h-100 d-none d-md-block d-lg-block d-xl-block"
        >
          <h3 class="fw-700 mt-5">The 700 Club Campaign</h3>
          <div class="row justify-content-center" style="margin-top: 165px">
            <div class="col-md-8 col-sm-12 d-flex align-items-center py-5">
              <p class="text-primary">
                We're launching the 700 Club Campaign so you can get a head
                start on your journey to do money different. We've bundled the
                most expansive set of tools in the market today to allow you to
                dramatically improve your credit score for a fraction of what
                those other guy's charge for initiation fees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-about-accordion d-lg-none d-xl-none">
    <h6 class="text-warning">Here’s what we value</h6>
    <h3 class="text-primary">Values</h3>
    <p>We are defined by our values.</p>

    <div class="about-accrordion">
      <ngb-accordion
        [closeOthers]="true"
        #a="ngbAccordion"
        activeIds="panel-0"
        class="border-0 custome-acc"
      >
        <ngb-panel
          class="border-0"
          *ngFor="let item of accData; let i = index"
          id="{{ 'panel-' + i }}"
        >
          <ng-template ngbPanelHeader let-opened="opened" class="border-0">
            <div
              class="accordion-button custom-header bg-white p-0"
              [class.collapsed]="!opened"
            >
              <button
                ngbPanelToggle
                class="p-0 btn d-flex justify-content-between container-fluid text-start ps-0 text-primary bg-white shadow-none border-0"
              >
                {{ item.title }}
                <div
                  class="fs-24 fw-700 text-primary bg-white arrow"
                  *ngIf="opened"
                >
                  -
                </div>
                <div class="fs-20 fw-700 text-primary bg-white" *ngIf="!opened">
                  +
                </div>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="accordion-text">
              {{ item.content }}
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
  <div class="d-flex flex-column">
    <div class="about-section-3 d-none d-md-none d-lg-block d-xl-block">
      <div class="container mb-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <p class="mt-5 fs-24 text-warning">Here’s what we value</p>
            <h1 class="fs-48 fw-700 text-white">Values</h1>
            <p class="text-white text-center px-5 fs-18 fs-300">
              We are defined by our values. Our core values are at the center of
              how we think about everything we do – from serving our members to
              building our company to even how we work together – everything
              begins and ends with these principles. At Nevly, we are defined by
              not just what we do but how we do it.
            </p>
          </div>
        </div>
      </div>
      <div
        id="slider"
        class="slider vh-100 position-sticky top-0 overflow-hidden d-flex"
        (scroll)="getScrollValue($event)"
      >
        <div class="row bg-img vw-100">
          <div class="col-md-5 position-relative">
            <p *ngIf="activeSlideMenu === 0 || activeSlideMenu === 7 || activeSlideMenu === 8" class="position-absolute" style="top:50%; left: -90px; transform: rotate(-90deg);font-size:42px;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf=" activeSlideMenu === 3" class="position-absolute" style="top:50%; left: -70px; transform: rotate(-90deg);font-size:42px;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf="activeSlideMenu === 1" class="position-absolute" style="top:50%; left:0px; transform: rotate(-90deg);font-size:42px;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf="activeSlideMenu === 2" class="position-absolute" style="top:50%; left: -120px; transform: rotate(-90deg);font-size:250%;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf="activeSlideMenu === 4" class="position-absolute" style="top:50%; left: -140px; transform: rotate(-90deg);font-size:250%;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf="activeSlideMenu === 5" class="position-absolute" style="top:50%; left: -40px; transform: rotate(-90deg);font-size:42px;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <p *ngIf="activeSlideMenu === 6" class="position-absolute" style="top:47%; left: -140px; transform: rotate(-90deg);font-size:240%;line-height:130%;color:white;">{{slidesArr[activeSlideMenu].subtitle}}</p>
            <div class="about-slider--navgation d-flex align-items-center" [ngStyle]="{
              'background-image':
                'url(' + slidesArr[activeSlideMenu].bgImg + ')'
            }">
            <p class="about-slider--text mb-0">Member’s Interest</p>
              <ul>
                <li *ngFor="let item of slidesArr;let i = index" class="pointer"  [ngClass]="{ 'active-slide': i == activeSlideMenu }" (click)="activeSlideMenu = i">{{item.subtitle}}</li>
              </ul>
            </div>
          </div>
          <div class="col-md-7 d-flex justify-content-end align-items-center p-0">
            <div class="about-slider--slide">
              <div class="row h-100">
                <div class="col-md-4">
                  <div class="d-flex align-items-center justify-content-center h-100">
                    <img
                      [src]="slidesArr[activeSlideMenu].img"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div
                  class="col-md-8 p-0 d-flex align-items-center about-slider--sperator"
                >
                  <div>
                    <h5 class="text-primary fs-28 fw-600 mb-4">
                      {{ slidesArr[activeSlideMenu].heading }}
                    </h5>
                    <p class="content text-primary fs-24">
                      {{ slidesArr[activeSlideMenu].content }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: '100vw'; height: 100vh; background-color: transparent"></div>
    </div>
  </div>
</div>
