<header class="fwhite">
<app-header></app-header>
</header>

<div class="section_hero">
  <div class="hero_left">
    <div class="hero_title">
      Let’s Do<br>Money Different
    </div>
    <div class="hero_t2">
      50% Off First Month
    </div>
    <div class="hero_t3">
      Nevly is a technology company founded with a relentless focus on a mission to help people improve their financial health and achieve financial freedom.
    </div>
    <div class="hero_t4">
      <a href="https://app.nevlymoney.com/#/register" class="join_button"><span class="jlabel">Get Started</span></a>
    </div>            
  </div>
  <div class="hero_right"></div>
</div>

<div class="section_mobilebg">
  <img src="../../assets/img/hero_alticons.png">
  <svg width="633" height="633" viewBox="0 0 633 633" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M340.856 632.046C515.128 618.594 645.498 466.415 632.046 292.144C618.594 117.872 466.415 -12.4976 292.144 0.954079C117.872 14.4057 -12.4976 166.585 0.954079 340.856C14.4057 515.128 166.585 645.498 340.856 632.046Z" fill="url(#paint0_linear_7_160)" fill-opacity="0.9"></path>
   <defs>
   <linearGradient id="paint0_linear_7_160" x1="138.946" y1="376.618" x2="365.386" y2="57.4844" gradientUnits="userSpaceOnUse">
   <stop stop-color="#25BFEB"></stop>
   <stop offset="1" stop-color="#5A55D3"></stop>
   </linearGradient>
   </defs>
   </svg>
 </div> 
 



<div class="section_arlo">
    <div class="sar_image">
      <img src="../../assets/img/money.diffsection.png">
    </div>    
      <div class="sar_text">
        <p>Nevly Money Difference</p>
        <p>Nevly Money is not just a credit repair tool. It’s a collection of tools that should allow you to see your credit score go up faster over a shorter period than simply by using any one tool alone. Our product allows you to improve your credit score faster and cheaper than anyone else in the space. Plus,  we’ll never charge you miscellaneous fees. That means that whether your score has hit its goal or is no longer improving, we’ll automatically pause your subscription. No random charges from us on your account—ever. We've truly designed a product that we believe represents the future of credit repair. The best part? Your first month is 50% off, and no initiation fees.</p>

    </div>
</div>

<div class="section_values">
  <div class="values_left"  [ngSwitch]="circle_sel">
        <div class="svalue_title">
          <span *ngSwitchCase="0">Our Values</span>
          <span *ngSwitchCase="1">Members First</span>
          <span *ngSwitchCase="2">Collaboration</span>
          <span *ngSwitchCase="3">Aim High</span>
          <span *ngSwitchCase="4">Data Driven</span>
          <span *ngSwitchCase="5">Impact Community</span>
        </div>
          
        <div class="svalue_circlebar">
            <svg [ngClass]="{'black': circle_sel === 0}" (click)="circle_sel=0"   viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
            <svg [ngClass]="{'black': circle_sel === 1}" (click)="circle_sel=1"   viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
            <svg [ngClass]="{'black': circle_sel === 3}" (click)="circle_sel=3"  viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
            <svg [ngClass]="{'black': circle_sel === 5}" (click)="circle_sel=5"   viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
            <svg [ngClass]="{'black': circle_sel === 4}" (click)="circle_sel=4"   viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
            <svg [ngClass]="{'black': circle_sel === 2}" (click)="circle_sel=2"   viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" /></svg>
        </div>
          
        <div class="svalue_text">
          <span *ngSwitchCase="0">
            Our core values are at the center of how we think about everything we do – from serving our members to building our company to even how we work together – everything begins and ends with these principles. 
            <br><br>At Nevly, we are defined by not just what we do but how we do it.
          </span>
          <span *ngSwitchCase="1">
            We built this company because we are passionate about improving the lives of our customers and that passion lies at the heart of every decision we make.
            Our products, services, brand and business model is designed to be durable over the long term to achieve our mission – that is why we strive to make every person who comes into contact with our company or any of its people a missionary. Forward together.
          </span>
          <span *ngSwitchCase="2">
            We solve problems together and help each other out along the way. We keep our commitments and communicate diligently when we can’t.
          </span>
          <span *ngSwitchCase="3">
            We learn from others then we write our own rules to be a pioneer in our industry and a north star for what is 
            possible when institutions are willing to test and ultimately reject assumptions that have stifled innovation for
            those most in need of it. <br><br>
            We recognize that what we are doing is so much bigger than us and we are ok with that...in fact we like it that way.            
          </span>
          <span *ngSwitchCase="4">
            We ask, listen, learn and collect data. We engage in constructive debate to reach conclusions and move forward together.
          </span>
          <span *ngSwitchCase="5">
            We are focused on building a great business and a great company in an open, accessible environment.<br><br>
            Improving the communities within which we operate is imprinted into our DNA.            
          </span>                    
        </div>

        <a not_onclick="window.analytics.track('Checkout CTA Click')" href="https://app.nevlymoney.com/#/register" class="join_button"><span class="jlabel">Get Started</span></a>
  </div>

  <div class="services-circle">
    <div class="svalue_title"   [ngSwitch]="circle_sel"> 
      <span *ngSwitchCase="0">Our Values</span>
      <span *ngSwitchCase="1">Members First</span>
      <span *ngSwitchCase="2">Collaboration</span>
      <span *ngSwitchCase="3">Aim High</span>
      <span *ngSwitchCase="4">Data Driven</span>
      <span *ngSwitchCase="5">Impact Community</span>
    </div>

    <div class="services-buttons">

      <div id="CI" style="left: 50%;transform: translateX(-50%);" [ngClass]="{'clicked': circle_sel === 1}" (click)="circle_sel=1" class="services-button">
        <img src="../../assets/img/value1.png">
        <h3>Member<br>First</h3>
      </div>
      <div id="CM" style="top: 47%;left:0;transform: translateY(-50%);"   [ngClass]="{'clicked': circle_sel === 2}"  (click)="circle_sel=2" class="services-button">
        <img src="../../assets/img/value5.png">
        <h3>Collaboration</h3>
      </div>
      <div id="CCB"  style="top: 47%;right: 0;transform: translateY(-50%);"  [ngClass]="{'clicked': circle_sel === 3}" (click)="circle_sel=3" class="services-button">
        <img src="../../assets/img/value2.png">
        <h3>Aim<br>High</h3>
      </div>
      <div id="PRR" style="bottom: -0.6%;left: 11%;"  [ngClass]="{'clicked': circle_sel === 4}" (click)="circle_sel=4" class="services-button">
        <img src="../../assets/img/value4.png">
        <h3>Data<br>Driven</h3>
      </div>
      <div id="RR" style="bottom: -0.6%;right: 9%;"  [ngClass]="{'clicked': circle_sel === 5}" (click)="circle_sel=5" class="services-button">
        <img src="../../assets/img/value3.png">
        <h3>Impact Community</h3>
      </div>

    </div>
    <svg width="516" height="508" viewBox="0 0 516 508" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M515.11 253.917C515.11 393.933 399.8 507.439 257.557 507.439C115.315 507.439 0.00488281 393.933 0.00488281 253.917C0.00488281 113.901 115.315 0.395264 257.557 0.395264C399.8 0.395264 515.11 113.901 515.11 253.917ZM41.7195 253.917C41.7195 371.256 138.353 466.377 257.557 466.377C376.761 466.377 473.395 371.256 473.395 253.917C473.395 136.579 376.761 41.4571 257.557 41.4571C138.353 41.4571 41.7195 136.579 41.7195 253.917Z" fill="#D9D9D9"></path>
      <path d="M515.11 253.917C515.11 393.933 399.8 507.439 257.557 507.439C115.315 507.439 0.00488281 393.933 0.00488281 253.917C0.00488281 113.901 115.315 0.395264 257.557 0.395264C399.8 0.395264 515.11 113.901 515.11 253.917ZM41.7195 253.917C41.7195 371.256 138.353 466.377 257.557 466.377C376.761 466.377 473.395 371.256 473.395 253.917C473.395 136.579 376.761 41.4571 257.557 41.4571C138.353 41.4571 41.7195 136.579 41.7195 253.917Z" fill="url(#paint0_linear_54_552)" fill-opacity="0.9"></path>

      <defs>
        <linearGradient id="paint0_linear_54_552" x1="113.072" y1="302.072" x2="293.493" y2="43.7549" gradientUnits="userSpaceOnUse">
        <stop stop-color="#25BFEB"></stop>
        <stop offset="1" stop-color="#5A55D3"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>

</div>


<div id='reviews' class="section_review" style="display:flex;justify-content: center;">
  <div style="width: 1400px;">

    <div class="review_bigtitle" style="padding-top: 45px;padding-bottom: 10px;">What do people think of us?</div>
    <div class="review_bigtitle">4.8 <span>out of 5</span></div>

    <div class="review_container" style="display:flex;justify-content: center;">
      <div class="review_arrows"><img (click)="prevReview()" src="../../assets/img/review.arrow.1.png"></div>

      <div class="review_boxes" id="rev_boxes">
        <div class="review_box">
          <div class="review_content">My experience with Nevly has been amazing.</div>
          <div class="review_stars">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png"> 
            <img src="../../assets/img/review.star1.png">
          </div>
          <div class="review_text">
            Since enrollment into its credit improvement program, they have helped me tremendously and have worked on my behalf to get all 
            the negative items on my credit report verified and even some removed. Nevly has possibly helped me save thousands of dollars 
            I would have paid out by having a low credit score. I’m able to easily log onto the client portal and access all my 
            documentation and monitor my progress. I will be sure to refer them to other people I know.
          </div>
          <div class="review_profile">
            <div><img src="../../assets/img/testimony.face1.png"></div>
            <div class="review_pername">
              <div>Angela Dade</div>
              <div class="reslim">Waldorf, MD</div>
            </div>
          </div>
        </div>

        <div class="review_box">
          <div class="review_content">Nevly made it easy</div>
          <div class="review_stars">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png"> 
            <img src="../../assets/img/review.star1.png">
          </div>
          <div class="review_text">
            Nevly made it easy for me to understand how they would handle my needs to get my credit back on track. Signing up for
            its credit improvement services was extremely easy and took less then 10 min and the best part is that I could do 
            everything directly from my cell phone. Working with Nevly has been one of the best things my husband and I have
            ever done. We have now started our journey to be buy our first home.
          </div>
          <div class="review_profile">
            <div><img src="../../assets/img/testimony.face2.png"></div>
            <div class="review_pername">
              <div>Hope Hickman Akins</div>
              <div class="reslim">Lawrenceville, GA</div>
            </div>
          </div>
        </div>

        <div class="review_box" >
          <div class="review_content">Thank you Nevly</div>
          <div class="review_stars">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png"> 
            <img src="../../assets/img/review.star1.png" style="filter: grayscale(100%);">
          </div>
          <div class="review_text">
            When I became a member with Nevly my credit score was at a 621 in December 2021. This has been a long journey for my
            children and myself. My goal is now a 725 score. I am now more confident than I have ever been and am looking forward to my 
            future. Nevly has removed 5 of 18 total negative items and several inquiries on my three credit reports in less than three 
            months. Thank you Nevly for allowing me to take charge of my credit life.
          </div>
          <div class="review_profile">
            <div><img src="../../assets/img/testimony.face3.png"></div>
            <div class="review_pername">
              <div>Kenesha Perrialliat</div>
              <div class="reslim">Riverview, Fl</div>
            </div>
          </div>
        </div>

        <div class="review_box" >
          <div class="review_content">Nevly has done so much in a short period</div>
          <div class="review_stars">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png"> 
            <img src="../../assets/img/review.star1.png">
          </div>
          <div class="review_text" style="width: 566px;">
            I am very satisfied thus far. Enrolling into the program was fast and easy. In about a month, I have had 4 accurate, 
            but old negative items removed. I’ve had terrible credit for years and I haven’t known where to go for help or 
            how to fix it. Nevly has done so much in a short period and I’m so glad I went to them for help. Their site is 
            easy to use. The updates are current and I am able to monitor my own progress and the work they are actually doing. 
            I have already began recommending this service to my friends. Thank you very much.
          </div>
          <div class="review_profile" style="margin-top: -4px;">
            <div><img src="../../assets/img/testimony.face4.png"></div>
            <div class="review_pername">
              <div>Tyler Irby</div>
              <div class="reslim">Silver Spring, MD</div>
            </div>
          </div>
        </div>      
        
        <!--div class="review_box">
          <div class="review_content">My experience with Nevly has been amazing.</div>
          <div class="review_stars">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png">
            <img src="../../assets/img/review.star1.png"><img src="../../assets/img/review.star1.png"> 
            <img src="../../assets/img/review.star1.png">
          </div>
          <div class="review_text">
            Since enrollment into its credit improvement program, they have helped me tremendously and have worked on my behalf to get all 
            the negative items on my credit report verified and even some removed. Nevly has possibly helped me save thousands of dollars 
            I would have paid out by having a low credit score. I’m able to easily log onto the client portal and access all my 
            documentation and monitor my progress. I will be sure to refer them to other people I know.
          </div>
          <div class="review_profile">
            <div><img src="../../assets/img/testimony.face1.png"></div>
            <div class="review_pername">
              <div>Angela Dade</div>
              <div class="reslim">Waldorf, MD</div>
            </div>
          </div>
        </div-->     


      </div>
      <div class="review_arrows raleft"><img (click)="nextReview()" src="../../assets/img/review.arrow.2.png"></div>

    </div>
  </div>

</div>

<div class="sectionphone">
  <div class="subsection">
    <div class="seside1">
      <h3 >
      <span>
      <strong>Enhance your credit<br />and earn $15 per month <br />when you refer a friend.&nbsp;</strong></span></h3>
      <a href="https://app.nevlymoney.com/#/register" class="join_button"><span class="jlabel">Get Started</span></a> 
    </div>
    <div class="seside2">
    	<img src="../../../assets/img/person.phone1.png">
    </div>
  </div>
</div>


<!--div style="margin-bottom:50px;background:linear-gradient( to right,#2A2727 0%, #2A2727 70% , #5A55D3 70%,#5A55D3 100% )">
  <div style="position:relative;width:1200px;height: 442px;overflow-y:hidden; left: 50%;transform: translate(-50%, 0); ">
    <div style="display: inline-block;width:645px;vertical-align: top;">
      <h3 style="line-height: 58px;   margin-top: 60px;    margin-bottom: 40px;"><span style="font-weight: 600; font-family: 'Open Sans'; font-size: 45px; color: rgb(255, 255, 255);"><strong>Get the first month free. Earn $15 per month <br>when you refer a friend.&nbsp;</strong></span></h3>

      <a href="https://app.nevlymoney.com/#/register" class="join_button"><span class="jlabel">Get Started</span></a> 
    </div>
    <div style="display: inline-block;width:545px;">
      <img src="../../../assets/img/person.phone1.png">
    </div>
  </div>
</div-->



<app-footer></app-footer>
