
  <div *ngIf="!isMenuCollapsed" class="grayoverlay"></div>
  <div *ngIf="!isMenuCollapsed" class="menumobile">
    <div class="mmo_close">
      <span (click)="isMenuCollapsed = !isMenuCollapsed"><mat-icon>close</mat-icon></span>
    </div>
    <div class="mmo_list">
      <div class="mmo_pricing"><a routerLink="/"  (click)="isMenuCollapsed = !isMenuCollapsed">About us</a></div>
      <div><a href="https://get.nevlymoney.com/credit-repair/#lp-pom-block-46">Services</a></div>
      <div ><a href="https://get.nevlymoney.com/credit-repair/#diff-chart" target="_self">Pricing</a></div>
      <div><a href="https://app.nevlymoney.com/#/login">Log in</a></div>
    </div>
    <div class="mmo_join"><a href="https://app.nevlymoney.com/#/register">Join now</a></div>
  </div>
  
  
  <div class="section_menu">
    <div id="lp-pom-box-101" class="lp-element lp-pom-box">
      <div id="lp-pom-box-101-color-overlay"></div>
      <div class="lp-element lp-pom-image" id="lp-pom-image-12">
        <div class="lp-pom-image-container" style="overflow: hidden;">
          <a href="https://get.nevlymoney.com/credit-repair/"><img src="../../assets/img/logo_top.png"  ></a>
        </div>
      </div>
      <div class="lp-element lp-code lp-button-menu" id="lp-code-92">
        <a class="lp-element lp-pom-button" href="https://app.nevlymoney.com/#/register"><span class="label">Join Now</span></a>
      </div>
      <a class="lp-element lp-pom-button b-not" id="lp-pom-button-119" href="https://get.nevlymoney.com/credit-repair/#lp-pom-block-46" target="_self"><span class="label">Services</span></a>
      <a class="lp-element lp-pom-button b-not" id="lp-pom-button-120" routerLink="/"><span class="label">About Us</span></a>
      <a class="lp-element lp-pom-button b-not" id="lp-pom-button-121" href="https://get.nevlymoney.com/credit-repair/#diff-chart" target="_self"><span class="label">Pricing</span></a>
      <a class="lp-element lp-pom-button b-not" id="lp-pom-button-123" href="https://app.nevlymoney.com/#/login">
         <span class="label">Login</span>
      </a>
      <div class="lp-element lp-code lp-button-menu " id="lp-code-158">
        <a class="lp-element lp-pom-button" not_onclick="window.analytics.track('Login CTA Click')" href="https://app.nevlymoney.com/#/login">
          <!--span class="label" [ngClass]="{'forcewhite': forceWhiteMenu}"  style="border:0px">Login</span-->
          <span class="label" style="border:0px">Login</span>
          
        </a>
      </div>
    </div>
  </div>
  
  <div class="section_momenu">
      <div><a href="ttps://get.nevlymoney.com/credit-repair/"><img src="../../assets/img/logo_top.png" width="120px"></a></div>
      <!--div class="molink"><a href="https://get.nevlymoney.com/credit-repair/#diff-chart"><span>Pricing</span></a></div-->
      <div class="molink"><a href="https://app.nevlymoney.com/#/register"><span>Join Now</span></a></div>
      <span (click)="isMenuCollapsed = !isMenuCollapsed"  *ngIf="isMenuCollapsed"><mat-icon>menu</mat-icon></span>
      <!--span (click)="isMenuCollapsed = !isMenuCollapsed"  *ngIf="!isMenuCollapsed"><mat-icon>close</mat-icon></span-->
  </div>



<!--
  <li class="nav-item mx-2 px-3" >
    <a class="nav-link" href="#pricing" (click)="isMenuCollapsed = true"
      [routerLinkActive]="'active-link-nav'" [ngClass]="getActivatedRoute() === 'pricing' ? 'active-link-nav fw-600' : ''">
      <i class="pricing-icon" [ngClass]="
      getActivatedRoute() === 'pricing' ? 'pricing-icon' + '--active' : ''
        "></i>
        Pricing</a>
  </li>
 -->