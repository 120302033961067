import { environment } from './../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  links : any ;
  personphone:boolean = true;
  constructor(public router: Router) { }


  ngOnInit(): void {
    this.links = environment;
  }

  goToUrl(link : any) {
    this.links.register === link && window.analytics.track('Checkout CTA Click');
    window.location.href = link;
  }

  pageView(to: string = '') {
    window.location.hash.startsWith('#') && to.startsWith('#') && window.analytics.page(`/${to.split('#')[1]}`)
  }
}
