import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';

// export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
//   override lazyLoad = true;
//   // accountUrl = 'genialhelp.zendesk.com';
//   accountUrl: string = 'nevlyhelp.zendesk.com';
//   callback(zE: any) {
//     // zE.setLocale('en');
//   }
// }

declare global {
  interface Window { analytics: any }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    },
    ),
    BrowserAnimationsModule,
    LayoutModule,
    PagesModule,
    ScullyLibModule,
    NgbModule,
    // NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
