import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  disabled = false;
  faqData = [{
    title: 'Credit Builder Card',
    list: [{
      q: 'Will applying require a hard credit check to apply? ',
      a : 'Nope! We think everyone deserves a chance to build credit, so we don’t check your credit score when you apply'
    },{
      q: 'Do I need to have a Nevly Checking Account to use Credit Builder?',
      a: 'Yes. We designed Credit Builder to work with the Nevly Checking Account so that you can move money instantly—across your Nevly accounts!'
    },{
      q: 'Does Nevly charge any fees for using Credit Builder?',
      a: 'No way! No fees...period'
    },{
      q: 'Can you explain how Credit Builder can help build my score?',
      a: 'Credit Builder offers features that help you stay on top of key factors that impact your credit score. Consistent use of Credit Builder can help you build on-time payment history, increase the length of your credit history over time, and more. We report to the major credit bureaus – TransUnion, Experian and Equifax.'
    },{
      q: 'What makes Credit Builder different from traditional credit cards?',
      a: 'Unlike  traditional  credit   cards,  Credit  Builder  helps  you  build  credit  with  no  annual   fees,  no  interest  and  no  credit check required to apply.  Credit Builder is a secured credit card. The money you move into Credit Builder’s secured account is the amount you can   spend   on   the   card.   Unlike   other   secured   credit   cards,   that   money   can   be   used   to   pay   off   your   monthly balances. Since Credit Builder doesn’t have a pre-set limit, spending up to the amount you added won’t contribute to a high-utilization record on your credit history.'
    }, {
      q : 'Is Credit Builder a secured credit card?',
      a: 'Yes, Credit Builder is a secured credit card. The money you move to the Credit Builder secured account is how much you can spend with the card. This amount is often referred to by other secured credit cards as the security deposit. Like other secured credit cards, Credit Builder also reports to the major credit bureaus, to help you build credit history over time. However, for most secured credit cards, security deposits are unavailable to you, the consumer, until you  close the account. With Credit  Builder, you can use your deposit to pay for monthly charges.  Plus, Credit Builder charges no annual fees and no interest, and no minimum-security deposit required!'
    }, {
      q : 'What is the credit limit? ',
      a: 'Credit   Builder   doesn’t   have   a   pre-set   credit   limit.   Instead,   the   money   you   move   into   your   Credit   Builder   secured account sets your spending limit on the card. With traditional credit cards, using a high percentage of your available credit limit could negatively impact your credit score. You don’t have to worry about that with Credit Builder because Nevly does not report credit utilization.  On-time payment history can have a positive impact on your credit score. Late payment may negatively impact your credit score. How much you can spend with Credit Builder is shown to you as Available to Spend in the Nevly app'
    }, {
      q : 'If I use all the money I add into Credit Builder, will Credit Builder report high utilization and hurt my credit score?',
      a: 'Nope. Credit Builder doesn’t report percent utilization to the major credit bureaus because it has no pre-set credit limit. That means spending up to the amount you added will not show a high-utilization card on your credit history. So rest assured to use Credit Builder for your everyday purchases and let them count towards credit building'
    }]
  },
    {
    title: 'Rent Reporting',
    list: [{
        q: 'I sublease a room/apartment, can I report my monthly rent? ',
        a : 'Yes you can! As long as you have a written agreement with the primary landlord / management company or owner of the home. We are unable to report any rent payments if you only have an agreement with the original renter that signed the rental agreement with the landlord or management company. (If you’re not on the main lease – you should request to be added).'
    } , 
    {
      q: 'Who is responsible to let you know if I move?  ',
      a : 'We  count   on  you   and  your   landlord   to  keep   us  informed  so   that  we  can   ensure   accurate   reporting   to  the   credit bureau. If we receive notice from your landlord that you are no longer renting from them, we will reach out to you for confirmation and then close out your account. '
  }, {
    q: 'Can you report my past addresses?  ',
    a: 'We are able to report the last 24 months of your past rental history (from today back 2 years). This helps to add age to your credit profile, which in turn provides a greater increase in your credit score. Of the five factors in determining your credit score, length of history contributes 15% to the mix. Upon enrolling, we will reach out to your landlord and confirm all information. Upon confirmation, you will receive credit for the last two years of your rent payments!'
  }, {
    q: 'I now live at a new address but have a previous address. Can I report both? ',
    a: 'Yes you can! We can report up to the last 24 months of your past address and do ongoing monthly reporting for your current address.'
  }, {
    q: 'Can I use your service if another company has already reported my address? ',
    a: 'No! TransUnion & Equifax only allows an address to be reported once on your credit report. If you have another company already reporting the address you want to enroll with us, we will not be able to report it for you.'
  }]
  }, {
    title : 'Credit Improvement',
    list: [
      {
        q : 'What is a credit score? ',
        a: 'credit score is a three-digit number between 300-850 that is calculated using the items on your credit report which represents your creditworthiness as an individual. The higher the score, the more favorable and trustworthy you are to creditors.'
      },{
        q: 'What is a credit report? ',
        a : 'A credit report is a document that essentially houses all of your credit information and is used to make up your credit scores. This includes things such as personal information, employment, credit accounts, loans, public records, and credit inquiries'
      }, {
        q : 'What is a credit bureau? ',
        a : 'Credit bureaus are the agencies that package all of your credit information and provide the credit reports. They then sell that information to businesses inquiring about your credit score and history. The most influential bureaus in the United States are TransUnion, Experian, and Equifax'
      }, {
        q : 'What are the primary components of my credit score?',
        a : 'Nevly leverages the TransUnion VantageScore 3.0 model which includes several key components:',
        points : [
          'Payment History - comprised of whether you regularly pay your bills on time.',
          'Credit Age and Type - average age of your credit accounts and mix of various types such as revolving debt and installment debt. An example of revolving debt is a credit card and an example of installment debt is a student loan',
          'Credit Utilization - the percentage of your credit limit you currently have outstanding',
          'Balances - balances on your credit accounts including current balances as well as delinquent accounts.',
          'Inquiries - recent inquiries or applications for new credit.',
          'Available Credit - amount of available credit that is unused'
        ]
      }
    ]
  },{
    title : 'Credit Monitoring',
    list: [{
      q : 'What accounts can I link?',
      a: 'You can link your deposit account (checking, savings), investment, and retirement accounts as well as credit cards, student loans, mortgages, and other liabilities. If you’re not able to link your account, you will be able to manually add an account or asset (like an owned car or home) so that you’re able to see your entire net worth'
    }, {
      q : 'What are the primary components of my credit score?',
      a : 'Nevly leverages the TransUnion VantageScore 3.0 model which includes several key components:',
      points : []
    },{
      q : 'Will checking my credit hurt my credit score?',
      a : 'Checking your own credit report is considered a soft inquiry, which does not impact your credit score'
    }, {
      q : 'Why does my credit score matter?',
      a : 'Your credit score is used by lenders, among others, as they review your applications for credit and determine your creditworthiness which might influence if they are willing to extend credit and at what terms. It might also be used by landlords, utility providers, and prospective employers'
    }]
  }]
  constructor(){}

  ngOnInit() {
    window.analytics.page('/faq')
  }

}
