import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModal {
  emailId = new FormControl('' , [Validators.required , Validators.email]);
  constructor(public activeModal: NgbActiveModal) {}

  getValue(){
    if(this.emailId.value){
      window.location.href = environment.register+'/'+this.emailId.value;
    }
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  review_register:number = 0;
  links: any;
  deferredPrompt: any;
  showButton = false;
  circle_sel:number= 0;
  public forceWhiteMenu = true;

  //public isMenuCollapsed = true;
  
/*
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
  }*/

  /*

  public activeSlideMenu: any = 0;
  public activeReview: any;

  @HostListener('window:scroll', ['$event'])
  getScrollValue(event: any) {
    const slider = document.querySelector('.slider')?.getBoundingClientRect();
    let height: any = slider?.height;
    let calc: any = height / 5;
    switch (true) {
      case window.pageYOffset > height && window.pageYOffset < height + calc:
        this.activeSlideMenu = 0;
        break;
      case window.pageYOffset > height + calc &&
        window.pageYOffset < height + calc * 2:
        this.activeSlideMenu = 1;
        break;
      case window.pageYOffset > height + calc * 2 &&
        window.pageYOffset < height + calc * 3:
        this.activeSlideMenu = 2;
        break;
      case window.pageYOffset > height + calc * 3 &&
        window.pageYOffset < height + calc * 4:
        this.activeSlideMenu = 3;
        break;
      case window.pageYOffset > height + calc * 4 &&
        window.pageYOffset < height + calc * 5:
        this.activeSlideMenu = 4;
        break;

      default:
    }
  }
  activeSlide = 0;*/

  constructor(
    public router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    router.events.subscribe((res) => {

      this.getServiceRoute(res);

    });
  }


/*
  //Add PWA to Homescreen
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }*/

  // Get Service Rotute to Access ids content
  getServiceRoute(data: any) {
    if (data.url !== undefined && data.url.startsWith('/#reviews')) {
        setTimeout(() => {
          document
            .getElementById('reviews')
            ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            console.log("scroll.rev");
        }, 500);
    }
  }

  ngOnInit(): void {
    //this.getPackages();
    this.links = environment;

    // Fire segment page view on page load
    window.location.hash.length > 0 ? this.pageView() : window.analytics.page('/');
    // this.modalService.open(CustomModal, { centered: true });
  }

  pageView(to: string = '') {
    const pseudoPages = to.startsWith('#') ? to.split('#') : window.location.hash.split('#')
    pseudoPages.length > 1 && window.analytics.page(`/${pseudoPages[1]}`)
  }

 
  prevReview(){
    this.review_register -= 1;

    if(window.innerWidth <= 780){
      if(this.review_register <= -1)this.review_register = 3;

      let boxes = document.querySelectorAll('.review_box');
      for (let i = 0; i < boxes.length; i++) {
        if(i == (this.review_register)){
          (boxes[i] as HTMLElement).style.display = "inline";
        } else {
          (boxes[i] as HTMLElement).style.display = "none";
        }

      }

    } else {
      if(this.review_register <= -1)this.review_register = 1;
      let boxes = document.querySelectorAll('.review_box');
      for (let i = 0; i < boxes.length; i++) {
        //work with checkboxes[i]
        (boxes[i] as HTMLElement).style.transform = "translateX(-"+  630*2*this.review_register + "px)";
      }
    }


  }

  nextReview(){
    this.review_register += 1;

    if(window.innerWidth <= 780){
      if(this.review_register >= 4)this.review_register = 1;

      let boxes = document.querySelectorAll('.review_box');
      for (let i = 0; i < boxes.length; i++) {
        if(i == (this.review_register)){
          (boxes[i] as HTMLElement).style.display = "inline";
        } else {
          (boxes[i] as HTMLElement).style.display = "none";
        }

      }

    } else {

      if(this.review_register >= 2)this.review_register = 0;
      let boxes = document.querySelectorAll('.review_box');
      for (let i = 0; i < boxes.length; i++) {
        //work with checkboxes[i]
        (boxes[i] as HTMLElement).style.transform = "translateX(-"+  630*2*(this.review_register ) + "px)";
      }
    }
  }


  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {

    if (window.pageYOffset >= 850) {
      (document.getElementById("lp-pom-box-101") as HTMLElement).classList.add("header-colored");
      (document.getElementById("lp-code-92") as HTMLElement).classList.add("header-colored");
      (document.getElementById("lp-code-158") as HTMLElement).classList.add("header-colored");
      

    } else {
      (document.getElementById("lp-pom-box-101") as HTMLElement).classList.remove("header-colored");
      (document.getElementById("lp-code-92") as HTMLElement).classList.remove("header-colored");
      (document.getElementById("lp-code-158") as HTMLElement).classList.remove("header-colored");
    }

  }
/*
  // Get Activated Route in URL
  getActivatedRoute() {
    return this.route.snapshot.fragment;
  }*/

  /*
  // Get Membership plans
  getPackages() {
    this.http
      .get(environment.apiUrl + `/stripe/subscription-plan-list`)
      .subscribe((res: any) => {
        if (res.status_code === 200) {
          let monthlyPricing = null;
          let yearlyPricing = null;
          res.data.subscriptionList.forEach((item: { planType: string; }) => {
            if (item.planType === 'yearly') {
              yearlyPricing = item;
            }
            if (item.planType === 'monthly') {
              monthlyPricing = item;
            }
          });
          this.packages.push(monthlyPricing);
          this.packages.push(yearlyPricing);
          // this.packages = res.data.subscriptionList;
        }
      });
  }*/
/*
  // Get Active Slide 
  getSlide(event: any) {
    this.activeSlide = +event.current;
  }*/

  goToUrl(link: any) {
    this.links.register === link && window.analytics.track('Checkout CTA Click');
    window.location.href = link;
  }
}
