<div class="modal-container">
  <div class="row">
    <div class="col-md-5"></div>
    <div
      class="col-md-7 h-100 py-3 py-md-4 py-lg-4 mt-2 py-xl-4 px-4 mx-3 px-md-0 px-lg-0 px-xl-0 mx-md-0 mx-lg-0 mx-xl-0"
    >
      <p
        class="text-end px-3 mx-3 skip"
        (click)="activeModal.close()"
      >
        Skip
        <img
          class="me-2"
          src="../../../assets/img/double-arrow.png"
          width="15px"
          height="15px"
        />
      </p>
      <p class="sub-heading-1">Earn as much as</p>
      <h1 class="heading-1">$180</h1>
      <p class="sub-heading-2">
        for each family member &<br />
        friend you <b class="fw-600">refer</b>.
      </p>
      <input
        class="dialog-input"
        type="text"
        [formControl]="emailId"
        name="emailId"
        placeholder="Sign up with your email"
      />
      <!-- <p *ngIf="emailId.invalid">Email Id is required.</p> -->
      <div *ngIf="emailId.invalid && (emailId.dirty || emailId.touched)">
        <div *ngIf="emailId.errors?.['required']">Email Id is required.</div>
        <div *ngIf="emailId.errors?.['email']">Email Id is not valid.</div>
      </div>
      <button class="dialog-btn" (click)="getValue()">
        <img src="../../../assets/img/arrow-right.png" />
      </button>
    </div>
  </div>
</div>
